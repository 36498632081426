import React from 'react'
import { useNavigate } from 'react-router'

import { BaseTextV2 } from 'components/Text'
import { CallCenter } from 'components/Icons'

import { PROFILE_ROUTES } from 'views/Profile/consts'
import { FOOTER_EVENTS, trackSimpleEvent } from 'services/analytics'
import messages from '../messages'
import SectionColumn from '../SectionColumn'

import { ContactHeader } from './styles'

const Contacts = ({ formatMessage }) => {
  const navigate = useNavigate()

  return (
    <SectionColumn title={formatMessage(messages.contactsTitle)}>
      <div>
        <ContactHeader
          $fullWidth
          onClick={ev => {
            ev.stopPropagation()
            ev.preventDefault()
            navigate(`${PROFILE_ROUTES.ROOT}?focus=your-contact`)
            trackSimpleEvent(FOOTER_EVENTS.CONTACTS_CLICK)
          }}
        >
          <CallCenter />
          <BaseTextV2>{formatMessage(messages.contactsPlaceholder)}</BaseTextV2>
        </ContactHeader>
      </div>
    </SectionColumn>
  )
}

export default Contacts
