import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { getProductTemplateInclusions } from 'utils'
import {
  ITEM_LIST_IDS,
  ITEM_LIST_NAMES,
  PDP_EVENTS,
  useProductAvailabilityTracking,
} from 'services/analytics'
import { useScrollElementIntoView } from 'utils/dom'
import { APP_BREAKPOINTS, ROUTES } from 'consts'
import { PRODUCTS_CAROUSEL_ID } from 'components/ProductsCarousel'
import { useOfMinWidth } from 'hooks'

import UnitSelection from 'views/ProductDetails/CartControlsSection/UnitSelection'
import ProductCartControls from 'components/ProductCartControls'

import { deliveryDatesByProductIdsSelector } from 'containers/Cart/selectors'
import { closestTimerDeliveryDateSelector } from 'containers/Delivery/selectors'
import BookmarkIcon from 'components/Product/BookmarkIcon'
import { BaseTextV2Secondary } from 'components/Text'
import {
  BookmarkIconContainer,
  BrandLine,
  CartControlsContainer,
  IconContainer,
  ProductControlsContainer,
  ProductNameContainer,
  ProductNameTopLine,
  ProductTitle,
  StickyContainer,
} from 'views/ProductDetails/CartControlsSection/styles'
import ProductFeatures from 'views/ProductDetails/CartControlsSection/ProductFeatures'
import SystemTemplateIcon from 'components/Product/SystemTemplateIcon'
import AvailabilityBox from './AvailabilityBox'
import OutOfOfferBox from './OutOfOfferBox'

const CartControlsSection = ({
  product,
  unitData,
  selectedUnit,
  setSelectedUnit,
  isSoonAvailable,
  hasReplacements,
  isUnitDisabled,
  isUnitOutOfStock,
}) => {
  const navigate = useNavigate()
  const closestDeliveryDate = useSelector(closestTimerDeliveryDateSelector)
  const productDeliveryOptions = useSelector(
    deliveryDatesByProductIdsSelector([product.id]),
  )

  const [isInUserTemplate, isInSystemTemplate] = useMemo(() =>
    getProductTemplateInclusions(unitData),
  )

  const trackProductAvailability = useProductAvailabilityTracking({
    product,
    unitData,
  })
  const trackSeeReplacementClick = () => {
    trackProductAvailability(PDP_EVENTS.SEE_REPLACEMENTS_CLICKED)
  }

  const scrollToReplacements = useScrollElementIntoView(PRODUCTS_CAROUSEL_ID)

  const isNotificationBtnShown =
    product.active &&
    !product.withdrawingSoon &&
    isUnitOutOfStock &&
    !unitData.isStockLimited

  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)
  const handleRedirectToBrand = () => {
    navigate(`${ROUTES.ALL_PRODUCTS}?filters[brands][]=${product.brand}`)
  }

  return (
    <ProductControlsContainer>
      <StickyContainer>
        <ProductNameContainer>
          <ProductNameTopLine
            onClick={() => !isDesktop && handleRedirectToBrand()}
          >
            <BrandLine onClick={() => isDesktop && handleRedirectToBrand()}>
              {product.brand}
            </BrandLine>
            <BaseTextV2Secondary data-test-id="product-id">
              {product.id}
            </BaseTextV2Secondary>
            {isInSystemTemplate && (
              <IconContainer data-test-id="system-template-icon">
                <SystemTemplateIcon placement="left" />
              </IconContainer>
            )}
          </ProductNameTopLine>
          <ProductTitle data-test-id="product-name">
            {product.name}
          </ProductTitle>
        </ProductNameContainer>

        <ProductFeatures product={product} />

        <UnitSelection
          product={product}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          isNotificationBtnShown={isNotificationBtnShown}
          scrollToReplacements={scrollToReplacements}
          trackSeeReplacementClick={trackSeeReplacementClick}
          hasReplacements={hasReplacements}
        />

        <div className="flex flex-col gap-1">
          {!product.active && <OutOfOfferBox product={product} />}

          <CartControlsContainer>
            <ProductCartControls
              product={product}
              unitData={unitData}
              isUnitDisabled={isUnitDisabled}
              isNotificationBtnShown={isNotificationBtnShown}
              hasReplacements={hasReplacements}
              scrollToReplacements={scrollToReplacements}
              trackSeeReplacementClick={trackSeeReplacementClick}
            />
            {product.active && (
              <BookmarkIcon
                Wrapper={BookmarkIconContainer}
                unitOfMeasure={selectedUnit}
                itemListName={ITEM_LIST_NAMES.PRODUCT_DETAILS}
                itemListId={ITEM_LIST_IDS.PRODUCT_DETAILS}
                {...{
                  product,
                  isInUserTemplate,
                }}
                clearTemplatesOnClose
              />
            )}
          </CartControlsContainer>
        </div>
        {product.active && (
          <AvailabilityBox
            product={product}
            unitData={unitData}
            closestDeliveryDate={closestDeliveryDate}
            isSoonAvailable={isSoonAvailable}
            productDeliveryOptions={productDeliveryOptions}
          />
        )}
      </StickyContainer>
    </ProductControlsContainer>
  )
}

export default CartControlsSection
