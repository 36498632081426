import React from 'react'
import { useModal } from '@ebay/nice-modal-react'
import { useNavigate } from 'react-router'
import { useIntl } from 'react-intl'

import { PROFILE_ROUTES } from 'containers/Profile/consts'
import { useGetSettingsQuery } from 'containers/Settings/rtkApi'
import { TwoFA } from 'components/Icons'
import Switch from 'components/Switch'

import Deactivate2FAModal from './Deactivate2FAModal'
import messages from './messages'

const TwoFactorAuth = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const { data: { twoFactorEnabled } = {} } = useGetSettingsQuery()

  const deactivateModal = useModal(Deactivate2FAModal)

  const handleBtnClick = () => {
    if (twoFactorEnabled) {
      deactivateModal.show()
    } else {
      navigate(PROFILE_ROUTES.ACTIVATE_2FA)
    }
  }

  return (
    <button
      type="button"
      className="px-4 py-2 rounded-lg flex gap-4 hover:bg-grey-150 w-full my-2"
      onClick={handleBtnClick}
    >
      <TwoFA />
      <span className="text-start flex-1 text-label-12">
        {formatMessage(messages.button)}
      </span>
      <Switch
        testId="two-factor-auth"
        value={twoFactorEnabled}
        className="p-0"
      />
    </button>
  )
}

export default TwoFactorAuth
