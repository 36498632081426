/* eslint-disable consistent-return */
import React, { useEffect } from 'react'
import { Portal } from 'react-portal'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { Flex } from '@rebass/grid'
import { getOr, toNumber } from 'lodash/fp'
import { useNavigate } from 'react-router'

import theme from 'theme'
import { ROUTES } from 'consts'
import { userDataSelector } from 'containers/UserInfo/selectors'
import cartActionsGroup from 'containers/Cart/actions'
import { CART_ITEM_ERROR } from 'containers/Cart/consts'
import { ProductRowBoxMissing } from 'components/Product'
import { BoldInfo } from 'components/Text'
import LoaderFullHeight from 'components/LoaderFullHeight'
import {
  trackMissingAlcoholLicense,
  trackAProductUnsufficientQuantity,
  trackResolveMissingItems,
} from 'services/analytics/missingItems'

import messages from './messages'
import {
  ItemsWrapper,
  Header,
  Count,
  HeaderContainer,
  SubmitButton,
} from './styles'
import { makeValidate, makeFormToApiValues } from './utils'

const MissingItemsList = ({
  items,
  headerTitle,
  headerSubTitle,
  renderProductError,
  isCartFlow,
  itemListId,
  itemListName,
  additionalEventParams,
  onTrackGetReplacements,
  onTrackShiftDelivery,
}) => {
  const dispatch = useDispatch()
  const userData = useSelector(userDataSelector)
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  useEffect(
    () => {
      if (!items?.length) {
        navigate(ROUTES.CART, { replace: true })
      }
    },
    [items, navigate],
  )

  const itemsRendered = items.map((product, index) => (
    <ProductRowBoxMissing
      key={product.productId}
      formatMessage={formatMessage}
      itemListName={itemListName}
      itemListId={itemListId}
      additionalEventParams={additionalEventParams}
      renderProductError={renderProductError}
      unitsOfMeasure={getOr([], 'product.unitsOfMeasure')(product)}
      onTrackGetReplacements={onTrackGetReplacements}
      onTrackShiftDelivery={onTrackShiftDelivery}
      index={index}
      {...product}
      {...{ isCartFlow }}
    />
  ))

  if (isCartFlow) {
    return (
      <Formik
        initialValues={{}}
        validate={makeValidate(items)}
        onSubmit={(values, { setSubmitting }) => {
          const parsedValues = makeFormToApiValues(items)(values)
          items.forEach(({ errorId, productId, quantityAdded }) => {
            if (errorId === CART_ITEM_ERROR.MISSING_ALCOHOL_LICENSE) {
              return trackMissingAlcoholLicense({ productId })
            }

            if (errorId === CART_ITEM_ERROR.UNSUFFICIENT_AMOUNT) {
              return trackAProductUnsufficientQuantity({
                productId,
                availableQuantity: quantityAdded,
                acceptedQuantity: toNumber(values[productId]),
              })
            }
          })

          dispatch(
            cartActionsGroup.updateDelta(parsedValues, {
              callback: () => setSubmitting(false),
              successCallback: () => {
                navigate(ROUTES.CART)
                trackResolveMissingItems({
                  storeId: userData.storeId,
                  customerNo: userData.customerNo,
                })
              },
            }),
          )
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <>
            <Form style={{ width: '100%' }}>
              <ItemsWrapper isCartFlow>
                <HeaderContainer>
                  <Flex flexDirection="column">
                    <BoldInfo style={{ marginBottom: theme.spacing.xs }}>
                      {headerTitle}
                    </BoldInfo>
                    <BoldInfo color={theme.colors.gray4}>
                      {headerSubTitle}
                    </BoldInfo>
                  </Flex>
                </HeaderContainer>

                {itemsRendered}
              </ItemsWrapper>

              <SubmitButton disabled={!isValid || !dirty || isSubmitting}>
                {formatMessage(messages.goToCartAndAccept)}
              </SubmitButton>
            </Form>

            {isSubmitting && (
              <Portal key="portal">
                <LoaderFullHeight />
              </Portal>
            )}
          </>
        )}
      </Formik>
    )
  }

  return (
    <ItemsWrapper>
      <HeaderContainer>
        <Header>
          {headerTitle}
          &nbsp;
        </Header>
        <Count>
          {items.length}
          &nbsp;
          {formatMessage(messages.numberOfProducts, {
            number: (items || []).length,
          })}
        </Count>
      </HeaderContainer>

      {itemsRendered}
    </ItemsWrapper>
  )
}

export default MissingItemsList
