import React, { useState } from 'react'
import { Navigate, useLocation } from 'react-router'

import OTP from 'components/OTP'
import { useRichFormatMessage } from 'utils'
import { BUTTON_VARIANTS } from 'components/Button/TwButton'
import { PROFILE_ROUTES } from 'containers/Profile/consts'
import LoaderFullHeight from 'components/LoaderFullHeight'
import { useToggle2FAHandlers } from 'views/Profile/utils'

import messages from './messages'

const Deactivate2FA = () => {
  const { state } = useLocation()
  const formatMessage = useRichFormatMessage()

  const [otpData, setOTPData] = useState(state)
  const [otpError, setOTPError] = useState(null)

  const {
    handleRequestOTP,
    handleSubmitOTP,
    isRequestOTPLoading,
    isToggle2FALoading,
  } = useToggle2FAHandlers({
    twoFactorEnabled: true,
    onRequestOTPSuccess: data => {
      setOTPData(data)
    },
    onInvalidOTP: () => {
      setOTPError(true)
    },
  })

  const isLoading = isRequestOTPLoading || isToggle2FALoading

  if (!state) {
    // redirect user to profile page if accessed directly
    return <Navigate replace to={PROFILE_ROUTES.PROFILE} />
  }

  return (
    <>
      {isLoading && <LoaderFullHeight />}
      <OTP
        onSubmit={handleSubmitOTP}
        otpData={otpData}
        requestOtp={handleRequestOTP}
        otpError={otpError}
        setOtpError={setOTPError}
        description={formatMessage(messages.otpDescription, {
          email: otpData.email,
        })}
        submitText={formatMessage(messages.otpSubmit)}
        isOtpLoading={isLoading}
        submitVariant={BUTTON_VARIANTS.alert}
      />
    </>
  )
}

export default Deactivate2FA
