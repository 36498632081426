import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'
import { Link } from 'react-router'
import { BaseTextV2 } from 'components/Text'
import Notification from 'components/Notification'
import { ALERT_TYPE } from 'components/Alert'
import theme from 'theme'

import { CatalogProductButtonsContainer } from 'components/Product/ProductButtons/styles'
import { CatalogSelectStyled } from 'components/VariantsSelect/styledComponents'
import {
  ProductLongBtn,
  ReplacementsBtn,
  ReplacementsBtnContainer,
} from '../ButtonsContainer/styles'
import { BadgeContainer } from '../Badge/styles'
import { AvailabilityWrapperV2 } from '../Availability/styles'
import { PromoText } from '../PromoGroup/styles'

const IMG_HEIGHT = 144

export const ProductLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  ${p =>
    p.$isCatalog &&
    `
    @media (max-width: 367px) {
      width: 208px;
      margin: 0 auto;
    }
  `};
`

export const ProductTileActions = styled(Flex)`
  flex-direction: column;
  margin-top: auto;
`

export const VariantsActions = styled(ProductTileActions)`
  background: ${theme.colors.lightGrey};
  border-radius: 8px;
`

// prettier-ignore
export const VariantsWrapper = styled(Flex)`
  padding: 4px;
  margin-bottom: -4px;
  ${props => props.isTooltipOpen && `
    opacity: 0.4;
    cursor: default;
  `};
`

export const IconsBar = styled(Flex)`
  padding: 8px 8px 0 8px;
  gap: ${theme.spacing.xs};
`

export const IconsBarSeparator = styled.div`
  width: 1px;
  height: 6px;
  align-self: center;
  background-color: ${theme.colors.borderFaded};
`

// prettier-ignore
export const variantSelectHover = css`
  background: ${theme.colors.lightGrey};
  ${CatalogProductButtonsContainer} {
    background: white;
    box-shadow: 0 2px 4px 0 #00000014;
    ${props => props.isVariants && `
      box-shadow: none;
      border-top: 1px solid ${theme.colors.lightGrey};
    `}
    button,
    input {
      background: white;
    }
  }
  ${ProductLongBtn} {
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  }
  ${CatalogSelectStyled} {
    .variantSelect__control {
      background: white;
    }
    .variantSelect__menu {
      background: white;
      box-shadow: 0px -2px 4px 0px #00000014;
      .variantSelect__single-value {
        background: white;
      }
    }
  }
  ${VariantsActions} {
    background: white;
    box-shadow: 0 2px 4px 0 #00000014;
  }
`

export const multipleVariantStyling = css`
  ${ReplacementsBtnContainer} {
    gap: 1px;
    border-radius: ${theme.borderRadius.base};

    ${ReplacementsBtn} {
      box-shadow: none;
    }
  }
`

// prettier-ignore
export const ProductBoxContainer = styled(Flex)`
  margin: auto;
  padding: 8px;
  border-radius: 8px;
  min-width: 176px;
  max-width: 208px;
  min-height: auto;
  height: 100%;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  background: ${theme.colors.white};
  :hover {
    ${variantSelectHover}
  }
  ${props => props.replacements && `margin-bottom: ${theme.spacing.base};`}
  ${props => props.isTooltipOpen && variantSelectHover}

  ${props => props.isVariants && multipleVariantStyling}
`

export const DefaultTemplateIcon = styled(Flex)``

export const TitleContainer = styled(Flex)`
  padding: 6px 8px;
`

// prettier-ignore
export const ProductInfo = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
`

export const PromoBadgeContainer = styled.div`
  position: absolute;
  max-width: calc(100% - 24px);
  top: ${IMG_HEIGHT + 13}px;
  margin-left: 8px;
  ${BadgeContainer} {
    border-radius: 6px;
    padding: 0 4px;
    min-height: 20px;
    > span {
      line-height: initial;
    }
  }
`

export const StockConfirmContainer = styled.div`
  position: absolute;
  z-index: ${theme.zIndex.base};
  left: 16px;
  bottom: -32px;
  right: 16px;
`

// prettier-ignore
export const ProductNotification = styled(Notification)`
  border-radius: 8px;
  font-family: ${theme.fonts.primary};
  padding: ${props => (props.visible ? '10px 8px;' : '0')};
  width: calc(100% - 16px);
  height: ${props => (props.visible ? '40px' : '0')};
  margin: ${props => (props.visible ? '8px 8px 0 8px;' : '0')};
  background: ${props =>
    props.type === ALERT_TYPE.INFO
      ? theme.colors.infoBlue
      : theme.colors.secondary};
  figure {
    display: none;
  }
  > div {
    padding: 0;
    min-height: auto;
    justify-content: center;
    background: ${props =>
    props.type === ALERT_TYPE.INFO
      ? theme.colors.infoBlue
      : theme.colors.secondary
};
    > ${BaseTextV2} {
      font-size: ${theme.textSizes.xs};
      text-align: center;
    }
  }
`

export const ProductBoxAvailabilityWrapper = styled(AvailabilityWrapperV2)`
  > span {
    padding: 6px 8px;
    border: 0;
    background: none;
    min-height: auto;
    > span {
      display: inline-block;
      font-family: ${theme.fonts.primary};
      font-size: ${theme.textSizes.sm};
      font-weight: 500;
      line-height: 20px;
    }
  }
`

export const ProductImgContainer = styled.div`
  width: 100%;
  height: ${IMG_HEIGHT}px;
  mix-blend-mode: multiply;
`

export const ProductBoxPromoText = styled(PromoText).attrs({
  isSmall: { lineHeight: true },
})`
  display: inline-block;
  padding: 6px 8px;
  font-weight: 500;
`

export const Spacer = styled.div`
  height: 16px;
`

export const SaleBarContainer = styled.div`
  display: flex;
  padding: ${theme.spacing.xs};
`
