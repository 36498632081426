import React from 'react'
import { useIntl } from 'react-intl'

import FeaturedCategoryTile from './FeaturedCategoryTile'
import FeaturedArticleTile from './FeaturedArticleTile'
import FeaturedQuestionAccordion from './FeaturedQuestionAccordion'

import messages from '../../messages'

const MainPage = ({
  featuredCategories,
  featuredArticles,
  featuredQuestions,
}) => {
  const { formatMessage } = useIntl()

  const hasFeaturedArticles = featuredArticles.length >= 2

  return (
    <div className="flex flex-col gap-10 w-full lg:gap-14">
      <div className="flex flex-col gap-8">
        <h2 className="text-h2 pl-2 lg:text-h1 lg:p-0">
          {formatMessage(messages.featuredCategories)}
        </h2>
        <div className="grid gap-4 lg:grid-cols-3 lg:gap-6">
          {featuredCategories.map(({ category, articles }) => (
            <FeaturedCategoryTile
              key={category.categoryId}
              category={category}
              articles={articles}
            />
          ))}
        </div>
      </div>
      {hasFeaturedArticles && (
        <div className="flex flex-col gap-8">
          <h2 className="text-h2 pl-2 lg:text-h1 lg:p-0">
            {formatMessage(messages.featuredArticles)}
          </h2>
          <div className="grid gap-2 md:grid-cols-2 lg:grid-cols-[repeat(auto-fit,minmax(0,1fr))] lg:gap-6">
            {featuredArticles.map(featuredArticle => (
              <FeaturedArticleTile
                key={featuredArticle.title}
                featuredArticle={featuredArticle}
              />
            ))}
          </div>
        </div>
      )}
      {!!featuredQuestions.length && (
        <div className="flex flex-col gap-8">
          <h2 className="text-h2 pl-2 lg:text-h1 lg:p-0">
            {formatMessage(messages.featuredQuestions)}
          </h2>
          <div className="flex flex-col gap-2 rounded-3xl bg-grey-200">
            {featuredQuestions.map(featuredQuestion => (
              <FeaturedQuestionAccordion
                key={featuredQuestion.title}
                featuredQuestion={featuredQuestion}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default MainPage
