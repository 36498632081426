import React, { useEffect, useState } from 'react'
import { reduxForm, Field } from 'redux-form/immutable'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'lodash/fp'
import { useParams } from 'react-router'

import withSpinner from 'hocs/withSpinner'
import AuthContainer, {
  PASSWORD_FORM_NAME,
  AUTH_REDUCER_NAME,
  VALIDATE_TOKEN_REDUCER_NAME,
  validateTokenErrorCodeSelector,
  validateTokenActions,
  resendSetPasswordActions,
  resendPasswordEmailSelector,
  userLoginSelector,
} from 'containers/Auth'
import { VALIDATE_ERRORS } from 'containers/Auth/consts'
import PasswordInput from 'components/Input/PasswordInput'
import { BUTTON_SIZE } from 'components/Button'

import { validatePassword } from '../SignUp/utils'

import { Title, StyledPrimaryBtn } from './styledComponents'
import Notification from '../Notification'
import messages from './messages'
import Expired from './Expired'
import Invalid from './Invalid'
import Succeed from './Succeed'
import Resend from './Resend'
import AlreadyUsed from './AlreadyUsed'

const Password = ({ input, ...props }) => (
  <PasswordInput {...input} {...props} />
)

const SetPassword = ({
  handleSubmit,
  submitting,
  submitSucceeded,
  submitFailed,
  fetching,
  pristine,
  change,
}) => {
  const dispatch = useDispatch()
  const { token } = useParams()
  const [resend, setResend] = useState(false)
  const { formatMessage } = useIntl()

  const tokenErrorCode = useSelector(validateTokenErrorCodeSelector)
  const resendPasswordStepEmail = useSelector(resendPasswordEmailSelector)
  const setPasswordStepEmail = useSelector(userLoginSelector)

  useEffect(() => {
    change('token', token)
    dispatch(validateTokenActions.delta({ token }))
  }, [])

  const resendMail = () => {
    dispatch(resendSetPasswordActions.delta({ token }))
    setResend(true)
  }

  if (fetching) {
    return null
  }

  if (tokenErrorCode === VALIDATE_ERRORS.INVALID) {
    return <Invalid />
  }

  if (resend) {
    return <Resend email={resendPasswordStepEmail} />
  }

  if (tokenErrorCode === VALIDATE_ERRORS.EXPIRED) {
    return <Expired onBtnClick={resendMail} />
  }

  if (tokenErrorCode === VALIDATE_ERRORS.ALREADY_USED) {
    return <AlreadyUsed />
  }

  if (!tokenErrorCode && submitSucceeded && !submitting && !submitFailed) {
    return <Succeed />
  }

  return (
    <Notification height="366px" bigHeader hideFooter header={messages.header}>
      {setPasswordStepEmail && (
        <Title>
          {formatMessage(messages.description, {
            value: (
              <span style={{ fontWeight: 600 }}>{setPasswordStepEmail}</span>
            ),
          })}
        </Title>
      )}
      <form onSubmit={handleSubmit}>
        <Field
          component={Password}
          label={formatMessage(messages.password)}
          name="password"
          dataAttr="set_password_input"
          placeholder="..."
          hint={formatMessage(messages.passwordShould)}
          errorHint={formatMessage(messages.passwordShould)}
        />
        <StyledPrimaryBtn
          size={BUTTON_SIZE.BLOCK}
          data-test-id="set_password_submit"
          disabled={pristine || submitting}
        >
          {formatMessage(messages.submit)}
        </StyledPrimaryBtn>
      </form>
    </Notification>
  )
}

export default compose(
  AuthContainer,
  reduxForm({
    form: PASSWORD_FORM_NAME,
    touchOnBlur: false,
    validate: validatePassword,
    initialValues: {
      token: '',
      password: '',
    },
    onSubmit: (values, _, props) => {
      props.setPassword(values, _, props)
    },
  }),
  withSpinner([[AUTH_REDUCER_NAME, VALIDATE_TOKEN_REDUCER_NAME]]),
)(SetPassword)
