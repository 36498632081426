import { generatePath } from 'react-router'
import { round } from 'lodash'

import { ROUTES } from 'consts'
import { PROMO_TYPES } from 'containers/Cart/consts'

export const getTotalPoints = (items, isPriceBased = false) =>
  items &&
  items.reduce((res, { product, unitsOfMeasure }) => {
    const units = unitsOfMeasure || product.unitsOfMeasure
    const count = units.reduce(
      (acc, { inCartQuantity, points, priceNet }) =>
        acc + round(inCartQuantity * (isPriceBased ? priceNet : points), 2),
      0,
    )

    return res + count
  }, 0)

export const getPrizeUnit = units => units?.find(unit => unit.prize)

export const getRegularUnit = units => units?.find(unit => !unit.prize)

export const generateCampaignPath = id => {
  if (!id) return undefined

  return generatePath(ROUTES.CAMPAIGN_DETAILS, { id })
}

export const isPointBasedPromoType = promotion =>
  PROMO_TYPES.POINTS_BASED === promotion?.type

export const isValueBasedPromoType = promotion =>
  promotion?.type === PROMO_TYPES.VALUE_BASED

export const isSalePromoType = promotion => PROMO_TYPES.SALE === promotion?.type

export const isDiscountPromoType = promotion =>
  PROMO_TYPES.DISCOUNT === promotion?.type

export const isQuantitivePromoType = promotion =>
  PROMO_TYPES.QUANTITIVE === promotion?.type

export const isRewardPromoType = promotion =>
  isPointBasedPromoType(promotion) || isValueBasedPromoType(promotion)

export const isOutOfCatalogRewardProduct = (promotion, prizeProduct) =>
  isRewardPromoType(promotion) && !prizeProduct?.inUserCatalog

export const isCatalogRewardProduct = product => {
  if (!product) return null

  const { id, promotion, inUserCatalog } = product

  return (
    promotion &&
    id === promotion.reward.productId &&
    inUserCatalog &&
    isRewardPromoType(promotion)
  )
}

export const isNonQuantitivePromoProduct = product =>
  product.active &&
  !!product.promotion &&
  !isQuantitivePromoType(product.promotion)
