import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router'

import {
  ALWAYS_ENTERABLE_ROUTES,
  UNAUTHENTICATED_ONLY_ROUTES,
  FLATTENED_ROUTES,
  ROUTES,
} from 'consts'
import { getAuthToken } from 'containers/Auth/tokenStorage'
import { isSessionExpiredSelector } from 'containers/Auth'
import { hasPathInRoutes } from 'utils/routes'

const AuthGuard = ({ children }) => {
  const token = getAuthToken()
  const { pathname, search } = useLocation()

  // needed for recalculating flags for 401 error case
  const isSessionExpired = useSelector(isSessionExpiredSelector)

  const { needsRedirectToLogin, needsRedirectToDashboard } = useMemo(
    () => {
      const routeExists = hasPathInRoutes({
        routes: FLATTENED_ROUTES,
        pathname,
      })
      if (!routeExists) return {}

      const alwaysEnterable = hasPathInRoutes({
        routes: ALWAYS_ENTERABLE_ROUTES,
        pathname,
      })
      const needsAuth = !hasPathInRoutes({
        routes: UNAUTHENTICATED_ONLY_ROUTES,
        pathname,
      })
      const isOnLogin = pathname === ROUTES.LOGIN
      const isOnDashboard = pathname === ROUTES.DASHBOARD

      return {
        needsRedirectToLogin:
          !alwaysEnterable && needsAuth && !token && !isOnLogin,
        needsRedirectToDashboard:
          !alwaysEnterable && !needsAuth && token && !isOnDashboard,
      }
    },
    [pathname, token, isSessionExpired, hasPathInRoutes],
  )

  if (needsRedirectToLogin) {
    return (
      <Navigate
        replace
        to={`${ROUTES.LOGIN}?redirectTo=${pathname + search}`}
      />
    )
  }
  if (needsRedirectToDashboard) {
    return <Navigate replace to={ROUTES.DASHBOARD} />
  }

  return children
}

export default AuthGuard
