import React from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useNavigate } from 'react-router'

import { PROFILE_ROUTES } from 'containers/Profile/consts'
import { useRichFormatMessage } from 'utils'
import { DescriptionText } from 'components/modal/Confirmation/styles'
import Confirmation from 'components/modal/Confirmation'
import LoaderFullHeight from 'components/LoaderFullHeight'
import { useToggle2FAHandlers } from 'views/Profile/utils'

import messages from './messages'

const Deactivate2FAModal = () => {
  const navigate = useNavigate()
  const modal = useModal()
  const formatMessage = useRichFormatMessage()

  const { handleRequestOTP, isRequestOTPLoading } = useToggle2FAHandlers({
    twoFactorEnabled: true,
    onAlreadySet: () => {
      modal.remove()
    },
    onRequestOTPSuccess: data => {
      modal.remove()
      navigate(PROFILE_ROUTES.DEACTIVATE_2FA, {
        state: data,
      })
    },
  })

  return (
    <>
      {isRequestOTPLoading && <LoaderFullHeight />}
      <Confirmation
        isOpen
        onClose={modal.remove}
        onConfirm={handleRequestOTP}
        headingText={formatMessage(messages.disableHeader)}
        leftBtnText={formatMessage(messages.cancel)}
        rightBtnText={formatMessage(messages.disableAccept)}
      >
        <DescriptionText>{formatMessage(messages.disableText)}</DescriptionText>
      </Confirmation>
    </>
  )
}

export default NiceModal.create(Deactivate2FAModal)
