import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'

import { ROUTES } from 'consts'
import { NoResults as NoResultsIcon, Home as HomeIcon } from 'components/Icons'
import NoResults from '..'

import messages from './messages'

const SearchNoResults = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  return (
    <NoResults
      icon={<NoResultsIcon />}
      text={formatMessage(messages.header)}
      btnIcon={<HomeIcon />}
      btnText={formatMessage(messages.btnText)}
      handleBtnClick={() => navigate(ROUTES.DASHBOARD)}
      containerTestId="search-no-results"
      btnTestId="search-no-results-btn"
    />
  )
}

export default SearchNoResults
