import React from 'react'
import { useIntl } from 'react-intl'
import _values from 'lodash/values'

import { generatePath } from 'react-router'
import { ROUTES } from 'consts'
import {
  Panel,
  Circle,
  Content,
  GroupsContainer,
  NumberText,
  SubcategoryNumberText,
  SingleBox,
  CategoryLink,
  SubCategoryLink,
  MoreLink,
  Description,
} from './styles'
import messages from './messages'

const PanelBox = ({ name, groups, id, productCount, imageUrl }) => {
  const { formatMessage } = useIntl()

  return (
    <SingleBox>
      <Panel>
        <Description>
          <CategoryLink to={generatePath(ROUTES.PRODUCTS, { category: id })}>
            {name}
          </CategoryLink>
          <NumberText>
            {productCount}
            &nbsp;
            {formatMessage(messages.productsCount, { number: productCount })}
          </NumberText>
        </Description>
        {imageUrl && <Circle imageUrl={imageUrl} />}
      </Panel>
      <Content>
        <GroupsContainer>
          {_values(groups).map(group => (
            <React.Fragment key={group.id}>
              <SubCategoryLink
                to={generatePath(ROUTES.PRODUCTS, {
                  category: id,
                  group: group.id,
                })}
              >
                {group.name}
                <SubcategoryNumberText ml="2px">
                  {group.productCount}
                </SubcategoryNumberText>
              </SubCategoryLink>
            </React.Fragment>
          ))}
        </GroupsContainer>
        <NumberText>
          <MoreLink
            to={generatePath(ROUTES.PRODUCTS, {
              category: id,
            })}
          >
            {formatMessage(messages.seeMore)}
          </MoreLink>
        </NumberText>
      </Content>
    </SingleBox>
  )
}

export default PanelBox
