import { useParams } from 'react-router'

import { FILTER_SECTION } from 'components/Filters/consts'

const useProductsSectionName = () => {
  const params = useParams()
  let sectionName = FILTER_SECTION.ALL

  if (params.subgroup) {
    sectionName = FILTER_SECTION.SUBGROUP
  } else if (params.group) {
    sectionName = FILTER_SECTION.GROUP
  } else if (params.category) {
    sectionName = FILTER_SECTION.CATEGORY
  }

  return sectionName
}

export default useProductsSectionName
