import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import theme from 'theme'

import { useRichFormatMessage } from 'utils'
import { useCreateProductSubscriptionMutation } from 'containers/Products/rtkApi'
import { productDetailsActions } from 'containers/ProductDetails/actions'
import { useProductSubscriptionTracking } from 'services/analytics'
import {
  Logistic as LogisticIcon,
  RaisedHand as HandIcon,
  Loader,
} from 'components/Icons'
import { notifySuccess } from 'components/Product/utils'

import messages from './messages'

const OUT_OF_OFFER_TYPE = 'out_of_offer'

const OutOfOfferBox = ({ product }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const formatRichMessage = useRichFormatMessage()

  const { id, subscriptions } = product

  const [
    createProductSubscription,
    { isLoading },
  ] = useCreateProductSubscriptionMutation()
  const trackProductSubscription = useProductSubscriptionTracking(product)
  const handleProductSubscription = async () => {
    const { error } = await createProductSubscription({
      id,
      type: OUT_OF_OFFER_TYPE,
    })

    dispatch(productDetailsActions.delta({ id }))

    if (error) {
      console.log('Error creating subscription for out of offer product', error)
      return null
    }

    trackProductSubscription()
    return dispatch(notifySuccess(formatMessage(messages.successToast)))
  }

  const isAlreadySubscribed = subscriptions?.some(
    sub => sub.type === OUT_OF_OFFER_TYPE,
  )
  if (isAlreadySubscribed) {
    return (
      <div className="flex flex-col gap-[2px]">
        <div className="flex gap-4 px-4 py-2 bg-blue-100 rounded-t-lg items-center">
          <div>
            <LogisticIcon />
          </div>
          <span className="text-blue-500 text-13">
            {formatRichMessage(messages.alreadySubscribed1)}
          </span>
        </div>
        <div className="flex gap-4 px-4 py-2 bg-blue-100 rounded-b-lg items-center">
          <div>
            <HandIcon />
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-blue-500 text-13md">
              {formatMessage(messages.alreadySubscribed2)}
            </span>
            <span className="text-blue-500 text-13">
              {formatMessage(messages.alreadySubscribed3)}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col bg-blue-100 rounded-lg">
      <div className="flex gap-4 p-4 items-center">
        <div>
          <LogisticIcon />
        </div>
        <span className="text-blue-500 text-13">
          {formatMessage(messages.clickBelow)}
        </span>
      </div>
      <button
        type="button"
        className="flex gap-4 rounded-lg bg-blue-500 justify-center items-center h-10 hover:bg-blue-600"
        onClick={handleProductSubscription}
      >
        {isLoading ? (
          <div
            data-test-id="out-of-offer-box-btn-loader"
            className="animate-spin"
          >
            <Loader color={theme.colors.white} />
          </div>
        ) : (
          <>
            <HandIcon color={theme.colors.white} />
            <span className="text-white text-label-13md">
              {formatMessage(messages.ctaBtn)}
            </span>
          </>
        )}
      </button>
    </div>
  )
}

export default OutOfOfferBox
