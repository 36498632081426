import React from 'react'
import { includes } from 'lodash'
import { Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'
import { isNil, omit, propEq } from 'lodash/fp'
import { useLocation } from 'react-router'

import theme from 'theme'
import { ICONS, ICONS_POSITION } from 'components/Icon'
import { BUTTON_SIZE, AlternateBtn, AddProductsButton } from 'components/Button'
import { increaseAmount, decreaseAmount } from 'components/Product/utils'
import StarBtn from 'components/Product/StarBtn'
import messages from 'components/Product/ButtonsContainer/messages'
import ProductLicense from 'components/Product/ProductLicense'

import Amount from 'components/Product/Amount'
import { ButtonsWrapper } from './styles'

// TODO: refactor (reduce duplication/simplify) both product button components
/* eslint-disable no-nested-ternary */

const ProductButtons = ({
  onStarClick,
  unitData,
  amount = unitData.multiplier,
  disableTrash,
  trashInsteadOfMinus = !disableTrash && Number(amount) === unitData.multiplier,
  onCartClick,
  onTrashClick,
  onInputChange,
  onInputBlur,
  onInputFocus,
  isInCart,
  cartBtnDisabled,
  hideStar,
  hideCart,
  active,
  fontSize = 14,
  productId,
  onPlusClick,
  onMinusClick,
  hasTextStarBtn,
  maxValue,
  disableInput,
  neverDisableMinus,
  product,
  ...rest
}) => {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const iconProps = {
    size: BUTTON_SIZE.ICON,
    iconPosition: ICONS_POSITION.CENTER,
  }

  const handlePlusClick = () => {
    const increasedAmount = increaseAmount(unitData, amount)
    onPlusClick(increasedAmount)
  }

  const handleMinusClick = () => {
    const decreasedAmount = decreaseAmount(unitData, amount)
    if (decreasedAmount > 0) {
      onMinusClick(decreasedAmount)
    }
  }

  return (
    <ButtonsWrapper
      noSpaceBetween={includes(location.pathname, 'templates')}
      {...omit(['onNotifyStockUnavailable'], rest)}
      unitData={unitData}
      col="12"
    >
      {hideStar ? (
        <div />
      ) : (
        <StarBtn {...{ onStarClick, active, productId, hasTextStarBtn }} />
      )}
      <Flex>
        {propEq('licenseMissing', true)(product) && !isInCart ? (
          <ProductLicense {...{ product }} />
        ) : (
            !hideCart && !isInCart ? (
              <AddProductsButton
                ml={theme.spacing.xs}
                onClick={onCartClick}
                data-test-id={`cart_${productId}`}
                disabled={cartBtnDisabled}
              >
                {formatMessage(messages.toCart)}
              </AddProductsButton>
            ) : (
              <React.Fragment>
                <AlternateBtn
                  {...iconProps}
                  disabled={
                    !neverDisableMinus &&
                    !trashInsteadOfMinus &&
                    Number(amount) === unitData.multiplier
                  }
                  data-test-id={`minus_${productId}`}
                  onClick={
                    trashInsteadOfMinus ||
                    Number(amount) === unitData.multiplier
                      ? onTrashClick
                      : handleMinusClick
                  }
                  iconType={trashInsteadOfMinus ? ICONS.TRASH : ICONS.MINUS}
                />
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    onCartClick()
                  }}
                >
                  <Amount
                    disabled={disableInput}
                    data-test-id={`input_${productId}`}
                    style={{ fontSize }}
                    value={amount}
                    onFocus={onInputFocus}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    displayUnit={unitData.unitOfMeasureDescription}
                  />
                </form>
                <AlternateBtn
                  onClick={handlePlusClick}
                  data-test-id={`plus_${productId}`}
                  {...iconProps}
                  iconType={ICONS.PLUS}
                  disabled={!isNil(maxValue) && amount >= maxValue}
                />
              </React.Fragment>
            )
        )}
      </Flex>
    </ButtonsWrapper>
  )
}

export default ProductButtons
