import React, { useEffect } from 'react'
import { compose } from 'lodash/fp'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import {
  AUTH_REDUCER_NAME,
  CONFIRM_EMAIL_REDUCER_NAME,
  confirmEmailActions,
  confirmEmailSelector,
  confirmErrorCodeSelector,
  isConfirmFetchedSelector,
  resendConfirmEmailActions,
} from 'containers/Auth'
import { CONFIRM_ERRORS } from 'containers/Auth/consts'
import withSpinner from 'hocs/withSpinner'

import Succeed from './Succeed'
import Expired from './Expired'
import Verified from './Verified'
import Invalid from './Invalid'
import Resend from './Resend'

const EmailConfirm = () => {
  const dispatch = useDispatch()

  const { token } = useParams()

  const confirmEmailError = useSelector(confirmErrorCodeSelector)
  const confirmStepEmail = useSelector(confirmEmailSelector)
  const isFetched = useSelector(isConfirmFetchedSelector)

  useEffect(() => {
    dispatch(confirmEmailActions.delta({ token }))

    return () => {
      dispatch(resendConfirmEmailActions.clear())
    }
  }, [])

  if (!isFetched) {
    return null
  }

  if (!confirmEmailError) {
    return <Succeed />
  }

  if (confirmEmailError === CONFIRM_ERRORS.INVALID) {
    return <Invalid />
  }

  if (confirmEmailError === CONFIRM_ERRORS.EXPIRED && !confirmStepEmail) {
    return (
      <Expired
        onButtonClick={() =>
          dispatch(resendConfirmEmailActions.delta({ token }))
        }
      />
    )
  }

  if (confirmEmailError === CONFIRM_ERRORS.EXPIRED && confirmStepEmail) {
    return <Resend email={confirmStepEmail} />
  }

  if (
    [CONFIRM_ERRORS.VERIFIED, CONFIRM_ERRORS.ALREADY_IN_PROGRESS].includes(
      confirmEmailError,
    )
  ) {
    return <Verified />
  }

  return null
}

export default compose(
  withSpinner([[AUTH_REDUCER_NAME, CONFIRM_EMAIL_REDUCER_NAME]]),
)(EmailConfirm)
