import React from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { DATE_TIME_FORMATS } from 'utils/datetime'
import { CalendarV2 as CalendarIcon } from 'components/Icons'
import TextTooltip from 'components/Tooltip/TextTooltip'
import OrderStatusTag from '../../OrderStatusTag'

import messages from '../messages'
import {
  HeaderContainer,
  HeaderTitle,
  SubtitleContainer,
  OrderDatetimeContainer,
  OrderDatetimeText,
} from './styles'

const Header = ({ orderDetails }) => {
  const { formatMessage } = useIntl()

  const { orderNumber, orderDatetime, orderStatusId } = orderDetails

  return (
    <HeaderContainer>
      <HeaderTitle>
        {`${formatMessage(messages.orderNumber)} ${orderNumber}`}
      </HeaderTitle>
      <SubtitleContainer>
        <OrderStatusTag
          orderStatusId={orderStatusId}
          className="lg:px-2"
          textClassName="lg:text-label-13"
        />
        <TextTooltip content={formatMessage(messages.orderDateTooltip)}>
          <OrderDatetimeContainer>
            <CalendarIcon />
            <OrderDatetimeText>
              {dayjs(orderDatetime).format(DATE_TIME_FORMATS.FULL_DATE_TIME)}
            </OrderDatetimeText>
          </OrderDatetimeContainer>
        </TextTooltip>
      </SubtitleContainer>
    </HeaderContainer>
  )
}

export default Header
