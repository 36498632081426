import React from 'react'

const Kebab = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="7.30078" width="1.4" height="16" rx="0.7" fill="#657280" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.38085 4.93564C3.17018 3.85155 3.87807 2.80189 4.9621 2.59095L5.45261 2.4955C7.13471 2.16817 8.86415 2.16817 10.5463 2.4955L11.0368 2.59095C12.1208 2.80189 12.8287 3.85155 12.618 4.93564L12.3128 6.50643C11.515 5.81627 10.5561 5.25774 9.80839 5.02768C9.47849 4.92617 9.12876 5.11132 9.02725 5.44123C8.92575 5.77113 9.1109 6.12086 9.4408 6.22237C10.0118 6.39807 10.872 6.8905 11.5897 7.53522C11.748 7.67745 11.8953 7.82347 12.0282 7.97089L11.6378 9.9795C11.012 9.53831 10.354 9.19555 9.80839 9.02768C9.47849 8.92617 9.12876 9.11132 9.02725 9.44123C8.92575 9.77113 9.1109 10.1209 9.4408 10.2224C9.9551 10.3806 10.7039 10.7958 11.372 11.3477L11.2759 11.8419C11.0324 13.0951 9.93485 13.9999 8.65823 13.9999H7.34063C6.06401 13.9999 4.96646 13.0951 4.72293 11.8419L4.6269 11.3478C5.29497 10.7958 6.04379 10.3806 6.5581 10.2224C6.88801 10.1209 7.07316 9.77113 6.97165 9.44123C6.87014 9.11132 6.52041 8.92617 6.19051 9.02768C5.64493 9.19555 4.98687 9.53833 4.36102 9.97952L3.97069 7.97092C4.1036 7.82349 4.25088 7.67746 4.40923 7.53522C5.12693 6.8905 5.98707 6.39807 6.5581 6.22237C6.88801 6.12086 7.07316 5.77113 6.97165 5.44123C6.87014 5.11132 6.52041 4.92617 6.19051 5.02768C5.44282 5.25774 4.48389 5.81629 3.6861 6.50646L3.38085 4.93564Z"
      fill="#8E9BA8"
    />
  </svg>
)

export default Kebab
