import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  NoOrders as NoOrdersIcon,
  NoResults as NoSearchResultsIcon,
  XNormal as ClearIcon,
} from 'components/Icons'
import TwButton, { BUTTON_VARIANTS } from 'components/Button/TwButton'

import messages from '../../../messages'

const NoOrders = ({ onClick, isQuery }) => {
  const { formatMessage } = useIntl()

  const { title, btnText } = useMemo(
    () => {
      if (isQuery) {
        return {
          title: messages.noSearchResultsMessage,
          btnText: messages.noSearchResultsCtaBtn,
        }
      }

      return {
        title: messages.noOrdersMessage,
        btnText: messages.noOrdersCtaBtn,
      }
    },
    [isQuery],
  )

  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col gap-8 w-[207px] self-center items-center">
        <div className="flex flex-col gap-4 items-center">
          {isQuery ? <NoSearchResultsIcon /> : <NoOrdersIcon />}
          <span className="text-13 text-center whitespace-break-spaces">
            {formatMessage(title)}
          </span>
        </div>
        <TwButton
          onClick={onClick}
          variant={BUTTON_VARIANTS.secondary}
          className="w-fit"
        >
          <div className="flex gap-4">
            {isQuery ? <ClearIcon /> : null}
            <span className="text-label-13md">{formatMessage(btnText)}</span>
          </div>
        </TwButton>
      </div>
    </div>
  )
}

export default NoOrders
