import React from 'react'
import { useIntl } from 'react-intl'

import messages from '../messages'

const NotificationFeedSection = ({
  children,
  title,
  displayReadAllBtn,
  markAllAsRead,
}) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <div className="flex justify-between pt-4 pr-6 pb-2 pl-4 lg:pt-2 lg:pr-8 lg:pl-6">
        <span className="text-base font-medium">{title}</span>
        {displayReadAllBtn && (
          <button
            type="button"
            className="text-xs leading-6 hover:text-green-600 transition duration-300"
            onClick={markAllAsRead}
          >
            {formatMessage(messages.markAllAsRead)}
          </button>
        )}
      </div>
      <div className="flex flex-col gap-1 lg:gap-0.5">{children}</div>
    </>
  )
}

export default NotificationFeedSection
