import React from 'react'
import styled from 'styled-components'

import theme from 'theme'
import { ITEM_LIST_NAMES, ITEM_LIST_IDS } from 'services/analytics'
import ProductBox from 'components/Product/ProductBox'

import { useLocation } from 'react-router'
import TemplateButtons from './TemplateButtons'

export const ReplacementsOuterWrapper = styled.div`
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
  grid-auto-flow: column;
  grid-column-gap: ${theme.spacing.sm};
`

const renderButtonsHandler = (
  templateId,
  product,
  handleAddToTemplate,
  handleRemoveFromTemplate,
  onGetTemplateItems,
) => ({ unitOfMeasure, showNotification }) => (
  <TemplateButtons
    templateId={templateId}
    product={product}
    unitOfMeasure={unitOfMeasure}
    handleAddToTemplate={handleAddToTemplate}
    handleRemoveFromTemplate={handleRemoveFromTemplate}
    showNotification={showNotification}
    onGetTemplateItems={onGetTemplateItems}
  />
)

const ReplacementsList = ({
  hideBookmark,
  replacements,
  onProductDetailsClick,
  templateId,
  onGetTemplateItems,
  onAddToTemplate,
  onRemoveFromTemplate,
}) => {
  const { pathname } = useLocation()
  const handleAddToTemplate = ({ product, unitOfMeasure, onSuccess }) => {
    onAddToTemplate(
      {
        template: { id: templateId },
        productId: product.id,
        quantity: 1,
        unitOfMeasure: unitOfMeasure.unitOfMeasure,
        path: pathname,
      },
      { onSuccess },
    )
  }
  const handleRemoveFromTemplate = ({ product, unitOfMeasure, onSuccess }) => {
    onRemoveFromTemplate(
      {
        template: { id: templateId },
        productId: product.id,
        unitOfMeasure: unitOfMeasure.unitOfMeasure,
        path: pathname,
      },
      { onSuccess },
    )
  }

  return (
    <ReplacementsOuterWrapper>
      {replacements.map((product, index) => (
        <ProductBox
          replacements
          key={product.id}
          {...product}
          onProductDetailsClick={onProductDetailsClick}
          isPromoGroupModal={false}
          {...{ hideBookmark, index, product }}
          renderButtons={
            templateId
              ? renderButtonsHandler(
                  templateId,
                  product,
                  handleAddToTemplate,
                  handleRemoveFromTemplate,
                  onGetTemplateItems,
                )
              : undefined
          }
          itemListId={ITEM_LIST_IDS.SUBSTITUTES}
          itemListName={ITEM_LIST_NAMES.SUBSTITUTES}
        />
      ))}
    </ReplacementsOuterWrapper>
  )
}

export default ReplacementsList
