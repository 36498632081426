import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ArrowDownWide } from 'components/Icons'
import { dashboardTilesDisplaySelector } from 'containers/App/selectors'
import { setDashboardTiles } from 'containers/App/actions/dashboardTiles'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import { cn } from 'utils'

const DashboardTile = ({
  title,
  subtitle,
  content,
  children,
  footer,
  disableCollapse,
  footerClassName,
  containerClassName,
  containerTestId = 'dashboard-tile-container',
  headerTestId = 'dashboard-tile-header',
  contentTestId = 'dashboard-tile-content',
  footerTestId = 'dashboard-tile-footer',
  onExpand = () => {},
  onCollapse = () => {},
}) => {
  const dispatch = useDispatch()
  const [isOpened, setOpened] = useState(false)
  const isOpenedGlobally = useSelector(dashboardTilesDisplaySelector)

  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)

  const isContentOpened = useMemo(
    () => {
      if (isTablet) {
        return isOpenedGlobally
      }
      return isOpened
    },
    [isOpened, isOpenedGlobally, isTablet],
  )

  const handleCollapseBtnClick = useCallback(
    () => {
      if (isContentOpened) {
        onCollapse()
      } else {
        onExpand()
      }

      if (isTablet) {
        dispatch(setDashboardTiles(!isContentOpened))
      } else {
        setOpened(!isOpened)
      }
    },
    [isContentOpened, isOpened, isTablet],
  )

  const expandHandler = useCallback(() => {
    setOpened(true)
    if (isTablet) {
      dispatch(setDashboardTiles(true))
    }
    onExpand()
  }, [])

  return (
    <div
      className={cn(
        'flex flex-col gap-6 rounded-2xl overflow-hidden bg-grey-150 py-4 px-2 md:px-4 md:pb-4 md:pt-6 lg:row-start-1 lg:group-[.top-campaigns]:row-start-2',
        containerClassName,
      )}
      data-test-id={containerTestId}
    >
      <div className="flex relative px-2" data-test-id={headerTestId}>
        <div className="w-[calc(100%-24px)] md:w-full">
          <h3 className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis text-base md:text-xl md:leading-8 text-grey-700">
            {title}
          </h3>
          <h3 className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis text-base md:text-xl md:leading-8">
            {subtitle}
          </h3>
        </div>
        {!disableCollapse && (
          <button
            type="button"
            data-test-id="tile-collapse-button"
            className={cn(
              'group/tileButton flex self-start ml-auto items-center justify-center cursor-pointer w-6 h-6 transition duration-300 relative top-1.5',
              isContentOpened
                ? 'scale-x-100 -scale-y-100'
                : '-scale-x-100 scale-y-100',
            )}
            onClick={handleCollapseBtnClick}
          >
            <ArrowDownWide className="group-hover/tileButton:stroke-blue-900" />
          </button>
        )}
      </div>
      {(children || content) && (
        <div
          data-test-id={contentTestId}
          className={cn(
            'h-full',
            !isContentOpened && !disableCollapse ? 'hidden' : 'block',
          )}
        >
          {children || content}
        </div>
      )}
      {footer && (
        <div
          className={cn('mt-auto', footerClassName)}
          data-test-id={footerTestId}
        >
          {React.cloneElement(footer, {
            expandTile: expandHandler,
            isContentOpened,
          })}
        </div>
      )}
    </div>
  )
}

export default DashboardTile
