import React from 'react'
import { useNavigate, generatePath } from 'react-router'

import { ROUTES } from 'consts'

const FeaturedArticleTile = ({
  featuredArticle: { title, categoryId, article },
}) => {
  const navigate = useNavigate()

  const { articleId } = article
  const handleClick = () =>
    navigate(
      generatePath(ROUTES.KNOWLEDGE_BASE, {
        category: categoryId,
        article: articleId,
      }),
    )

  return (
    <button
      data-test-id="kb-featured-article"
      type="button"
      className="flex flex-col gap-2 p-6 text-left rounded-3xl overflow-hidden bg-grey-200 hover:bg-grey-400 lg:gap-8 lg:p-8 lg:break-anywhere lg:overflow-visible"
      onClick={handleClick}
    >
      <h3 className="text-h3 lg:text-h2">{title}</h3>
      <span className="text-12 leading-4 w-full overflow-hidden text-ellipsis whitespace-nowrap lg:text-13 lg:overflow-visible lg:text-clip lg:whitespace-normal">
        {article.title}
      </span>
    </button>
  )
}

export default FeaturedArticleTile
