import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router'

import { ORDERS_ROUTES } from 'views/Orders/consts'
import {
  EVENT_ORIGIN,
  trackPastOrderClick,
  trackSeeAllOrdersClick,
} from 'services/analytics'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { useGetOrdersQuery } from 'containers/Orders/List/rtkApi'

import TileComponent from './TileComponent'

const OrdersTile = () => {
  const navigate = useNavigate()
  const { customerNo, storeId } = useSelector(userDataSelector)

  const { data } = useGetOrdersQuery({
    perPage: 1,
  })

  const orders = data?.orders || []
  const latestOrder = orders[0]

  const handleOrderHistoryClick = useCallback(
    () => {
      navigate(ORDERS_ROUTES.LIST)
      trackSeeAllOrdersClick({
        customerNo,
        origin: EVENT_ORIGIN.DASHBOARD,
      })
    },
    [navigate],
  )

  const handleOrderClick = useCallback(
    () => {
      trackPastOrderClick({
        orderNumber: latestOrder?.orderNumber,
        customerNo,
        orderStatusName: latestOrder?.orderStatusName,
        origin: EVENT_ORIGIN.DASHBOARD,
      })
      navigate(
        generatePath(ORDERS_ROUTES.DETAILS, { orderId: latestOrder?.orderId }),
      )
    },
    [latestOrder, navigate, customerNo],
  )

  return (
    <TileComponent
      latestOrder={latestOrder}
      hasNoOrders={!orders.length}
      onOrderDetailsClick={handleOrderClick}
      onAllOrdersClick={handleOrderHistoryClick}
      customerNo={customerNo}
      storeId={storeId}
    />
  )
}

export default OrdersTile
