import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { useCustomFormikHandlers } from 'hooks'
import { extractFirstError, useRichFormatMessage } from 'utils'
import { withSpinner } from 'hocs'
import { ROUTES } from 'consts'

import { resetPasswordActions } from 'containers/Profile/ResetPassword/actions'
import { resetPasswordInitErrorSelector } from 'containers/Profile/ResetPassword/selectors'
import {
  RESET_PASSWORD_INIT_REDUCER_NAME,
  RESET_PASSWORD_REDUCER_NAME,
} from 'containers/Profile/ResetPassword/consts'

import { ArrowLongRight } from 'components/Icons'
import FormInputField from 'components/Fields/FormInputField'
import NotificationWrapper from 'components/NotificationWrapper'
import InfoPanel from 'views/Profile/ResetPassword/InfoPanel'

import theme from 'theme'
import {
  Divider,
  FormSection,
  InputFieldContainer,
  InputLabel,
  SubmitButton,
} from '../styledComponents'
import resetSchema from './validationSchema'
import messages from '../messages'

const ResetPassword = () => {
  const dispatch = useDispatch()
  const formatMessage = useRichFormatMessage()
  const navigate = useNavigate()

  const [isResetSent, setResentSent] = useState(false)

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useCustomFormikHandlers({
    initialValues: {
      login: '',
    },
    onSubmit: fieldValues => {
      dispatch(
        resetPasswordActions.createDelta(fieldValues, {
          successCallback: () => setResentSent(true),
        }),
      )
    },
    validationSchema: resetSchema,
  })

  const initError = useSelector(resetPasswordInitErrorSelector)
  const serverError = extractFirstError(initError)

  useEffect(
    () => {
      if (serverError) {
        dispatch(resetPasswordActions.clear())
      }
    },
    [values],
  )

  return isResetSent ? (
    <InfoPanel
      title={formatMessage(messages.sentTitle)}
      subtitle={formatMessage(messages.sentSubtitle, { login: values.login })}
      btnText={formatMessage(messages.sentCtaBtn)}
      onBtnClick={() => navigate(ROUTES.LOGIN)}
    />
  ) : (
    <NotificationWrapper
      title={formatMessage(messages.title)}
      subtitle={formatMessage(messages.secondaryTitle)}
    >
      <FormSection onSubmit={handleSubmit}>
        <Divider />
        <InputFieldContainer>
          <InputLabel>{formatMessage(messages.login)}</InputLabel>
          <FormInputField
            name="login"
            value={values.login}
            touched={touched.login}
            testId="reset_pwd_field_login"
            placeholder={formatMessage(messages.login)}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errorText={
              serverError
                ? formatMessage(messages[serverError.code])
                : formatMessage(errors.login) || ''
            }
            isError={serverError || (touched.login && errors.login)}
          />
        </InputFieldContainer>
        <Divider />
        <SubmitButton data-test-id="reset_pwd_submit_login" type="submit">
          {formatMessage(messages.ctaBtn)}
          <ArrowLongRight color={theme.colors.white} />
        </SubmitButton>
      </FormSection>
    </NotificationWrapper>
  )
}

export default withSpinner([
  [RESET_PASSWORD_REDUCER_NAME, RESET_PASSWORD_INIT_REDUCER_NAME],
])(ResetPassword)
