import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router'
import Autoplay from 'embla-carousel-autoplay'
import { useIntl } from 'react-intl'

import { campaignsActions } from 'containers/Campaigns/actions'
import { selectCampaignsData } from 'containers/Campaigns/selectors'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { generateCampaignPath } from 'containers/Promotions/utils'
import { trackCampaignBannerClicked } from 'services/analytics/campaigns'
import Carousel from 'components/Carousel'

import {
  OverflowContainer,
  ScrollContainer,
  CampaignPlaceholderContainer,
  CampaignPlaceholderText,
  CampaignSliderImg,
  CampaignsTileContainer,
} from './styles'
import CarouselControls from './CarouselControls'
import campaignPlaceholderDesktop from './assets/campaing_placeholder_bg.png'
import messages from './messages'

const CampaignTile = () => {
  const isMobile = !useOfMinWidth(APP_BREAKPOINTS.TABLET_START)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(campaignsActions.delta())
  }, [])

  const campaigns = useSelector(selectCampaignsData)
  const { customerNo } = useSelector(userDataSelector)

  const { formatMessage } = useIntl()

  if (!campaigns?.length && isMobile) {
    return null
  }

  if (campaigns?.length) {
    return (
      <CampaignsTileContainer data-test-id="campaigns-tile-container">
        <Carousel
          carouselConfig={{
            loop: true,
          }}
          carouselPlugins={[Autoplay({ delay: 5000 })]}
          ScrollContainer={ScrollContainer}
          OverflowContainer={OverflowContainer}
          customControls={{
            component: CarouselControls,
          }}
        >
          {campaigns.map(({ imageUrl, id, picture, title }) => {
            const imgUrl = isMobile ? picture.urlMobile : picture.urlWeb

            return (
              <RouterLink
                key={id}
                to={generateCampaignPath(id)}
                onClick={() =>
                  trackCampaignBannerClicked({ title, customerNo, id })
                }
              >
                <CampaignSliderImg alt="campaign" src={imgUrl || imageUrl} />
              </RouterLink>
            )
          })}
        </Carousel>
      </CampaignsTileContainer>
    )
  }

  return (
    <CampaignsTileContainer data-test-id="campaigns-tile-container">
      <CampaignSliderImg
        alt="welcome banner"
        src={campaignPlaceholderDesktop}
      />
      <CampaignPlaceholderContainer>
        <CampaignPlaceholderText>
          {formatMessage(messages.placeholderText)}
        </CampaignPlaceholderText>
      </CampaignPlaceholderContainer>
    </CampaignsTileContainer>
  )
}

export default CampaignTile
