import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import usePrevious from 'use-previous'
import parse from 'html-react-parser'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Modal from 'components/modal'
import { MODAL_SIZES } from 'containers/App/modalTypes'
import { MODAL_BODY_CLASSNAME } from 'components/modal/ModalWrapper'
import { selectPromoGroupData } from 'containers/Promotions/Groups/selectors'
import { getPromoGroupProductsActions } from 'containers/Promotions/GroupProducts/actions'
import {
  selectPromoGroupProductsData,
  isPromoGroupProductsFetching,
  selectStandardPromotions,
} from 'containers/Promotions/GroupProducts/selectors'
import { ITEM_LIST_NAMES } from 'services/analytics'

import Loader from 'components/Loader'
import { ToTop } from 'components/Icons'
import { getPromoProduct } from 'containers/Promotions/Products/actions'
import { isCartRoute } from 'views/Cart/utils'
import { isPointBasedPromoType } from 'containers/Promotions/utils'
import ProductBox from 'components/Product/ProductBox'
import PrizeRow from '../PrizeRow'

import {
  ModalHeaderStyled,
  ProductsContainer,
  ToTopButton,
  PromoProductsList,
  TopContainer,
  Description,
  Url,
  UrlsContainer,
  PrizeRowsContainer,
} from './styles'

const PromoGroupModal = NiceModal.create(({ groupId }) => {
  const modal = useModal()
  const dispatch = useDispatch()
  const location = useLocation()
  const [isBtnVisible, setIsBtnVisible] = useState(false)
  const [initialOffset, setInitialOffset] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const { title, description, urls } = useSelector(selectPromoGroupData) || {}
  const { products, pagination } =
    useSelector(selectPromoGroupProductsData) || {}
  const isProductsFetching = useSelector(isPromoGroupProductsFetching)
  const standardPromos = useSelector(selectStandardPromotions)
  const isTopContainer = description || !!urls?.length
  const isPrizeRow = !!standardPromos.length

  const containerRef = useRef(null)
  const buttonRef = useRef(null)

  useEffect(
    () => {
      if (buttonRef.current && !initialOffset) {
        setInitialOffset(buttonRef.current.offsetTop)
      }
    },
    [buttonRef],
  )

  const scrollEl = containerRef.current?.getScrollElement()
  const goToTop = () => {
    scrollEl.scrollTop = 0
  }

  const prevCurrentPage = usePrevious(currentPage)
  const handleScroll = () => {
    if (buttonRef.current && initialOffset) {
      const offsetDiff = buttonRef.current.offsetTop - initialOffset >= 200
      if (!isBtnVisible && offsetDiff) {
        setIsBtnVisible(true)
      } else if (isBtnVisible && !offsetDiff) {
        setIsBtnVisible(false)
      }

      const scrollHeight = scrollEl.scrollHeight - scrollEl.offsetHeight
      const scrollDiff = scrollHeight - scrollEl.scrollTop <= 600
      if (
        currentPage < pagination.totalPages &&
        prevCurrentPage !== currentPage + 1 &&
        scrollDiff &&
        !isProductsFetching
      ) {
        dispatch(
          getPromoGroupProductsActions.delta({
            groupId,
            page: currentPage + 1,
          }),
        )
        setCurrentPage(currentPage + 1)
      }
    }
  }

  const modalHeaderStyled = (
    <ModalHeaderStyled
      heading={title}
      onClick={modal.remove}
      dataTestId="close-promo-modal"
    />
  )

  return (
    <Modal
      size={MODAL_SIZES.BIG}
      bodyClassName={MODAL_BODY_CLASSNAME.PROMO_GROUP}
      handleClose={modal.remove}
      hideHeader
    >
      {!isPrizeRow && modalHeaderStyled}
      <ProductsContainer
        scrollableNodeProps={{
          onScroll() {
            handleScroll()
          },
        }}
        ref={containerRef}
        $isPrizeRow={isPrizeRow}
      >
        {isPrizeRow && modalHeaderStyled}
        {isTopContainer && (
          <TopContainer>
            {description && (
              <Description isMarginRight={!!urls.length}>
                {parse(description)}
              </Description>
            )}
            {!!urls.length && (
              <UrlsContainer>
                {urls.map(({ url, name }) => (
                  <Url key={name} href={url} target="_blank">
                    {name}
                  </Url>
                ))}
              </UrlsContainer>
            )}
          </TopContainer>
        )}
        {isPrizeRow && (
          <PrizeRowsContainer data-test-id="prize-rows-container">
            {standardPromos.map(promo => (
              <PrizeRow
                key={promo?.id}
                closeModal={modal.remove}
                promo={promo}
              />
            ))}
          </PrizeRowsContainer>
        )}
        <PromoProductsList>
          {products?.length &&
            products.map((product, index) => {
              const { id, promotion, unitsOfMeasure } = product
              const isAnyInCart = unitsOfMeasure.some(
                unit => !!unit.inCartQuantity,
              )

              return (
                <ProductBox
                  key={id}
                  onProductDetailsClick={modal.remove}
                  onRemoveFromCart={() => {
                    dispatch(getPromoProduct.delta({ productId: id }))
                  }}
                  refetchOnCartClick={
                    (isPointBasedPromoType(promotion) && !isAnyInCart) ||
                    isCartRoute(location)
                  }
                  suppressGetDeliveryDates
                  isPromoGroupModal={false}
                  itemListId={groupId}
                  itemListName={ITEM_LIST_NAMES.PROMO_MODAL}
                  additionalEventParams={{
                    promo_group_id: groupId,
                    promo_group_name: title,
                  }}
                  index={index}
                  {...product}
                  {...{ product }}
                />
              )
            })}
        </PromoProductsList>
        {isProductsFetching && <Loader />}
        <ToTopButton onClick={goToTop} ref={buttonRef} {...{ isBtnVisible }}>
          <ToTop />
        </ToTopButton>
      </ProductsContainer>
    </Modal>
  )
})

export default PromoGroupModal
