import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'

import { categoryActions } from 'containers/Categories/actions'
import { categorySelector } from 'containers/Categories/selectors'
import { BreadCrumb } from 'components/BreadCrumbs'
import { retailTrackCategoryView } from 'services/analytics/retail'

import { ROUTES } from 'consts'
import ProductsBreadcrumbs from './ProductsBreadcrumbs'
import Title from './Title'
import { useCatalogTitle, useFetchOnQueryChange } from './utils'

const Category = ({ id, isPDP }) => {
  useFetchOnQueryChange({ actions: categoryActions, id })

  const { name } = useSelector(categorySelector)
  const title = useCatalogTitle(name)

  useEffect(
    () => {
      if (name && !isPDP) {
        retailTrackCategoryView({
          categoryName: name,
        })
      }
    },
    [name, isPDP],
  )

  return (
    <>
      <ProductsBreadcrumbs>
        {isPDP && (
          <BreadCrumb
            to={generatePath(ROUTES.PRODUCTS, {
              category: id,
            })}
          >
            {name}
          </BreadCrumb>
        )}
      </ProductsBreadcrumbs>
      {!isPDP && <Title title={title} />}
    </>
  )
}

export default Category
