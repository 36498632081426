import React from 'react'

import Article from './Article'
import Category from './Category'
import MainPage from './MainPage'

const EntryContent = ({
  selectedArticle,
  selectedCategory,
  featuredCategories,
  featuredArticles,
  featuredQuestions,
}) => {
  if (selectedArticle) {
    return <Article selectedArticle={selectedArticle} />
  }

  if (selectedCategory) {
    return <Category selectedCategory={selectedCategory} />
  }

  return (
    <MainPage
      featuredCategories={featuredCategories}
      featuredArticles={featuredArticles}
      featuredQuestions={featuredQuestions}
    />
  )
}

export default EntryContent
