import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'

import { trackEditedQty, trackEditedVariant } from 'services/analytics'
import {
  calculateAmountByQuantity,
  isLoweredPrice,
} from 'components/Product/utils'
import { templateItemsActions } from 'containers/Templates/actions'
import {
  ConfirmBtn,
  Container,
  ProductButtonsContainer,
  TrashBtn,
  VariantBtn,
  VariantBtnsContainer,
  VariantBtnText,
  VariantCheckbox,
} from 'components/Product/TemplateProductRow/QuantityButtons/styles'
import { formatPricePerUnit } from 'utils'
import { CheckV2 as ConfirmIcon, Trash as RemoveIcon } from 'components/Icons'
import theme from 'theme'
import { PopoverClose } from 'components/Popup'
import QuantityControls from 'components/ProductCartControls/QuantityControls'

const TooltipContent = ({
  product,
  selectedUnit,
  selectedQuantity,
  unitsOfMeasure,
  isTemplateAutomatic,
  onRemove,
}) => {
  const { id: templateId } = useParams()
  const dispatch = useDispatch()
  const [tooltipUnit, setTooltipUnit] = useState(selectedUnit)
  const [tooltipQuantity, setTooltipQuantity] = useState(selectedQuantity)

  const { promotion, id } = product

  const handleSelectedUnitChange = unit => {
    setTooltipQuantity(1)
    setTooltipUnit(unit)
  }
  const handleQuantityChange = value => setTooltipQuantity(value)

  const updateUnitCallback = useCallback(
    () => {
      if (tooltipUnit.unitOfMeasure !== selectedUnit.unitOfMeasure) {
        trackEditedVariant({
          productId: product.id,
          templateId,
          variant: tooltipUnit,
        })
      } else if (tooltipQuantity !== selectedQuantity) {
        trackEditedQty(
          product.id,
          templateId,
          calculateAmountByQuantity(tooltipUnit, tooltipQuantity),
        )
      }
    },
    [
      product,
      selectedUnit,
      selectedQuantity,
      templateId,
      tooltipUnit,
      tooltipQuantity,
    ],
  )

  const handleUpdateTemplateUnit = () => {
    dispatch(
      templateItemsActions.updateDelta({
        templateId,
        productId: product.id,
        successCallback: updateUnitCallback,
        quantity: tooltipQuantity,
        unitOfMeasure: tooltipUnit.unitOfMeasure,
      }),
    )
  }

  return (
    <Container data-test-id={`variant-tooltip-${id}`}>
      {!isTemplateAutomatic && (
        <VariantBtnsContainer>
          {unitsOfMeasure.map(unit => {
            const { priceNet, unitOfMeasureDescription, unitOfMeasure } = unit

            const price = isLoweredPrice(promotion)
              ? promotion.reward.priceNet
              : priceNet
            const isSelected = tooltipUnit.unitOfMeasure === unitOfMeasure

            return (
              <VariantBtn
                onClick={() => handleSelectedUnitChange(unit)}
                key={`${id}_${unitOfMeasure}`}
                data-test-id={`template-variant-btn-${unitOfMeasure}`}
              >
                <VariantBtnText>
                  {formatPricePerUnit(price, unitOfMeasureDescription)}
                </VariantBtnText>
                <VariantCheckbox>
                  {isSelected && (
                    <ConfirmIcon
                      color={theme.colors.secondary}
                      data-test-id={`template-variant-check-${unitOfMeasure}`}
                    />
                  )}
                </VariantCheckbox>
              </VariantBtn>
            )
          })}
        </VariantBtnsContainer>
      )}
      <ProductButtonsContainer>
        <PopoverClose>
          <TrashBtn onClick={onRemove} data-test-id="template-tooltip-trash">
            <RemoveIcon />
          </TrashBtn>
        </PopoverClose>
        <QuantityControls
          product={product}
          unitData={tooltipUnit}
          quantity={tooltipQuantity}
          onQuantityChange={handleQuantityChange}
          minValue={1}
        />
        <PopoverClose>
          <ConfirmBtn
            onClick={handleUpdateTemplateUnit}
            data-test-id="template-tooltip-check"
          >
            <ConfirmIcon />
          </ConfirmBtn>
        </PopoverClose>
      </ProductButtonsContainer>
    </Container>
  )
}

export default TooltipContent
