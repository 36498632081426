import React from 'react'
import { useIntl } from 'react-intl'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Confirmation from 'components/modal/Confirmation'
import {
  BUTTONS_VARIANT,
  DescriptionText,
} from 'components/modal/Confirmation/styles'
import { ACCOUNT_ERRORS } from 'containers/Auth'

import { useNavigate } from 'react-router'
import { ROUTES } from 'consts'
import messages from './messages'

const contentMapper = {
  header: {
    [ACCOUNT_ERRORS.EXISTS]: 'accountExists',
    [ACCOUNT_ERRORS.NOT_VERIFIED]: 'emailUsedForReg',
    [ACCOUNT_ERRORS.REGISTRATION_IN_PROGRESS]: 'emailUsedForReg',
  },
  subHeader: {
    [ACCOUNT_ERRORS.EXISTS]: 'emailUsed',
    [ACCOUNT_ERRORS.NOT_VERIFIED]: 'emailNotVerified',
    [ACCOUNT_ERRORS.REGISTRATION_IN_PROGRESS]: 'emailUsedForRegMsg',
  },
}

const SignUpErrorModal = ({ errorCode }) => {
  const navigate = useNavigate()
  const headerMsg = contentMapper.header[errorCode] || 'somethingWentWrong'
  const subHeaderMsg = contentMapper.subHeader[errorCode]
  const accountExists = errorCode === ACCOUNT_ERRORS.EXISTS
  const modal = useModal()
  const { formatMessage } = useIntl()

  const handleGoToLogin = () => {
    navigate(ROUTES.LOGIN)
    modal.remove()
  }

  if (!errorCode) {
    return null
  }

  return (
    <Confirmation
      isOpen
      onClose={modal.remove}
      onConfirm={modal.remove}
      headingText={formatMessage(messages[headerMsg])}
      onLeftBtnClick={handleGoToLogin}
      leftBtnText={accountExists && formatMessage(messages.toLogin)}
      rightBtnText={formatMessage(messages.backToForm)}
      btnsVariant={BUTTONS_VARIANT.SELECT}
      headerClassName="!items-center"
    >
      <DescriptionText>{formatMessage(messages[subHeaderMsg])}</DescriptionText>
    </Confirmation>
  )
}

export default NiceModal.create(SignUpErrorModal)
