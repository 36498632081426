import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, generatePath } from 'react-router'

import theme from 'theme'
import { ROUTES } from 'consts'
import { Settings, SignOut, Switch } from 'components/Icons/NavMenu'
import {
  Document as DocumentIcon,
  History as HistoryIcon,
  CircleInfo as InfoIcon,
  ArrowRightV2 as ArrowIcon,
} from 'components/Icons'
import {
  userDataSelector,
  multipleLocationsSelector,
} from 'containers/UserInfo/selectors'
import { logout } from 'containers/Auth/actions'
import { formatPrice, formatDeliveryAddress } from 'utils'
import { trackSimpleEvent, NAV_BAR_EVENTS } from 'services/analytics'
import { useGetFinancesQuery } from 'containers/Invoices/rtkApi'

import messages from './messages'
import StoreInfo from './StoreInfo'
import MenuItem from './MenuItem'
import { Separator } from '../styles'
import InvoicesMenuItem from './InvoicesMenuItem'
import { Container, MenuItemContainer } from './styles'

const UserMenu = ({ tradeLimit, openContactForm, openKnowledgeBaseMenu }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const userData = useSelector(userDataSelector)
  const hasMultipleLocations = useSelector(multipleLocationsSelector)
  const { data: invoicesSummary } = useGetFinancesQuery()

  const invoicesFinancesSummary = useMemo(
    () => {
      if (invoicesSummary?.overdueAmount) {
        return formatMessage(messages.invoicesExpired, {
          amount: formatPrice(invoicesSummary.overdueAmount),
        })
      }
      return invoicesSummary?.toPayAmount
        ? formatMessage(messages.invoicesDues, {
            amount: formatPrice(invoicesSummary.toPayAmount),
          })
        : null
    },
    [invoicesSummary],
  )

  const handleNavigateLocationSelect = useCallback(
    () => {
      navigate(ROUTES.CHANGE_LOCATION)
      trackSimpleEvent(NAV_BAR_EVENTS.SWITCH_ACCOUNT_CLICKED)
    },
    [navigate],
  )
  const handleLogout = useCallback(
    () => {
      trackSimpleEvent(NAV_BAR_EVENTS.LOGOUT_CLICKED)
      dispatch(
        logout({
          callback() {
            navigate(ROUTES.LOGIN)
          },
        }),
      )
    },
    [navigate],
  )
  const handleNavigateSettings = useCallback(
    () => {
      navigate(generatePath(ROUTES.PROFILE))
      trackSimpleEvent(NAV_BAR_EVENTS.SETTINGS_CLICKED)
    },
    [navigate],
  )
  const handleNavigateOrders = useCallback(
    () => {
      navigate(ROUTES.ORDERS.LIST)
      trackSimpleEvent(NAV_BAR_EVENTS.ORDERS_CLICKED)
    },
    [navigate],
  )
  const handleNavigateInvoices = useCallback(
    () => {
      navigate(ROUTES.INVOICES.ROOT)
    },
    [navigate],
  )
  const handleContactForm = useCallback(() => {
    openContactForm()
  }, [])

  const handleKnowledgeBaseItemSelect = () => {
    if (openKnowledgeBaseMenu) return openKnowledgeBaseMenu()

    return navigate(generatePath(ROUTES.KNOWLEDGE_BASE))
  }

  const showInvoicesMenuItem = !userData.hideDues && !userData.hideInvoices

  return (
    <Container>
      <StoreInfo
        customerName={userData.customerName}
        deliveryAddress={formatDeliveryAddress(
          userData.deliveryAddress,
          userData.deliveryPostcode,
          userData.deliveryCity,
        )}
      />

      {hasMultipleLocations && (
        <MenuItemContainer>
          <MenuItem
            icon={<Switch />}
            title={formatMessage(messages.changeLocation)}
            supplementLabel={userData.customerNo}
            onSelect={handleNavigateLocationSelect}
            testId="change-location"
          />
        </MenuItemContainer>
      )}

      <Separator />

      <MenuItem
        icon={<Settings />}
        title={formatMessage(messages.settings)}
        onSelect={handleNavigateSettings}
        testId="manageProfil"
      />
      <MenuItemContainer>
        <MenuItem
          icon={<HistoryIcon />}
          title={formatMessage(messages.orders)}
          onSelect={handleNavigateOrders}
          testId="orders"
        />
      </MenuItemContainer>
      {showInvoicesMenuItem && (
        <MenuItemContainer>
          <InvoicesMenuItem
            onSelect={handleNavigateInvoices}
            supplementLabel={invoicesFinancesSummary}
            paymentColorName={tradeLimit?.paymentColorName}
          />
        </MenuItemContainer>
      )}

      <Separator />

      <MenuItem
        icon={<InfoIcon />}
        title={formatMessage(messages.knowledgeBase)}
        onSelect={handleKnowledgeBaseItemSelect}
        supplementLabel={
          openKnowledgeBaseMenu && <ArrowIcon color={theme.colors.gray5} />
        }
      />
      <MenuItem
        icon={<DocumentIcon />}
        title={formatMessage(messages.contactForm)}
        testId="contact-form-btn"
        onSelect={handleContactForm}
      />
      <MenuItem
        icon={<SignOut />}
        title={formatMessage(messages.logout)}
        testId="logout"
        onSelect={handleLogout}
      />
    </Container>
  )
}

export default UserMenu
