import React from 'react'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'
import { useNavigate, generatePath } from 'react-router'

import { ORDERS_ROUTES } from 'views/Orders/consts'

import messages from '../../messages'
import RouteDetails from './RouteDetails'
import { DetailsContainer, DeliveryText } from './styles'

const ROUTE_INFO_BY_ORDER = new Map([
  [0, messages.routeMorning],
  [1, messages.routeAfternoon],
  [2, messages.routeEvening],
])

const DeliveryDayDetails = ({ routes = [] }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const orderSelectHandler = route => {
    const [order, ...restOrders] = route.orders
    if (restOrders.length) {
      return navigate(ORDERS_ROUTES.LIST)
    }
    return navigate(generatePath(ORDERS_ROUTES.DETAILS, { orderId: order.id }))
  }

  const renderDetails = children => (
    <DetailsContainer>{children}</DetailsContainer>
  )

  const allPlannedRoutes = routes.filter(r => r.orders.length > 0)
  if (allPlannedRoutes.length) {
    return renderDetails(
      allPlannedRoutes.map((route, index) => (
        <RouteDetails
          key={route.name}
          route={route}
          routeInfoMsg={
            ROUTE_INFO_BY_ORDER.get(index) || ROUTE_INFO_BY_ORDER.get(2)
          }
          onSelect={() => orderSelectHandler(route)}
          showRouteLabel={allPlannedRoutes.length > 1}
        />
      )),
    )
  }

  let msg = formatMessage(messages.noDeliveries)
  const isDeliveryAvailable = !!routes.length
  if (isDeliveryAvailable) {
    const orderDeadline = dayjs(routes[0].orderDeadline).format('D MMMM HH:mm')

    msg = formatMessage(messages.orderUntil, { date: orderDeadline })
  }

  return renderDetails(<DeliveryText>{msg}</DeliveryText>)
}

export default DeliveryDayDetails
