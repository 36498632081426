import { isPlainObject } from 'lodash/fp'
import _some from 'lodash/some'
import { matchPath } from 'react-router'

export const flattenRoutes = array =>
  array.reduce((acc, item) => {
    if (isPlainObject(item)) {
      return [...acc, ...flattenRoutes(Object.values(item))]
    }
    return [...acc, item]
  }, [])

export const hasPathInRoutes = ({ routes, pathname }) =>
  _some(routes, route => !!matchPath({ path: route, end: true }, pathname))
