import { round } from 'lodash'
import { get, getOr, find, head, compose, prop, propOr } from 'lodash/fp'
import { generatePath } from 'react-router'
import dayjs from 'dayjs'

import { ROUTES } from 'consts'
import { ALERT_TYPE } from 'components/Alert/consts'
import { notifyAction } from 'containers/App/actions'
import { makeMessages, makeTranslation, isOutOfStock } from 'utils'
import productBoxMessages from 'components/Product/ProductBox/messages'
import {
  isDiscountPromoType,
  isSalePromoType,
} from 'containers/Promotions/utils'
import { OFFER_FEATURES_VALUES } from './consts'

const messages = makeMessages(
  {
    soldForShort: 'soldForShort',
    soldFor: 'soldFor',
  },
  makeTranslation('product'),
)

const WEIGHTED_UNIT = 'KG'

export const parseAmount = amount => String(amount).replace('.', ',')

export const getCartUnitDef = ({
  unitQuantity,
  unitDesc,
  baseUnitDesc,
  unitOfMeasure,
  formatMessage = () => '',
  multiplier,
}) => {
  if (
    (unitOfMeasure !== WEIGHTED_UNIT && unitQuantity === 1) ||
    (unitOfMeasure === WEIGHTED_UNIT && multiplier === 1)
  ) {
    return `1 ${unitDesc}`
  }

  const textMulti = formatMessage(messages.soldForShort, {
    amount: parseAmount(multiplier),
    unit: baseUnitDesc,
  })
  const multiInfo =
    unitOfMeasure === WEIGHTED_UNIT
      ? textMulti
      : `${parseAmount(round(unitQuantity, 3))} ${baseUnitDesc}`
  return `1 ${unitDesc}${multiInfo ? `(${multiInfo})` : ''}`
}

export const getUnitDef = ({ unitQuantity, unitDesc, baseUnitDesc }) => {
  if (unitQuantity === 1) return `1 ${unitDesc}`
  return `1 ${unitDesc} (${parseAmount(
    round(unitQuantity, 3),
  )} ${baseUnitDesc})`
}

export const findUnitOfMeasureByVariant = (unitsOfMeasure, variant) => {
  const variantByValue =
    variant && find({ unitOfMeasure: variant.value }, unitsOfMeasure)
  if (!variantByValue) return head(unitsOfMeasure)
  return variantByValue
}

export const findProductUnitOfMeasure = (product, unitOfMeasure) =>
  compose(
    find({ unitOfMeasure }),
    propOr([], 'unitsOfMeasure'),
  )(product)

export const calculateAmountByQuantity = ({ multiplier }, quantity = 1) =>
  round(multiplier * quantity, 3)
export const calculateQuantityByAmount = ({ multiplier }, amount) => {
  if (amount) {
    return round(amount / multiplier)
  }
  return 1
}

export const decreaseAmount = ({ multiplier }, amount) =>
  round(amount - multiplier, 3)
export const increaseAmount = ({ multiplier }, amount) =>
  round(Number(amount) + multiplier, 3)

export const roundEnteredAmount = ({ multiplier }, amount) => {
  const parsedAmount = parseFloat(amount)
  if (Number.isNaN(parsedAmount) || !parsedAmount) {
    return 0
  }
  const roundedQuantity = calculateQuantityByAmount(
    { multiplier },
    parsedAmount,
  )
  return calculateAmountByQuantity({ multiplier }, round(roundedQuantity))
}

export const notifySuccess = message => notifyAction({ message })

export const notifyFailure = message =>
  notifyAction({ message, type: ALERT_TYPE.ERROR })

export const getNotificationMsg = ({ justAddedToCart, removedFromCart }) => {
  const updatedMsg = justAddedToCart ? 'addedToCart' : 'cartUpdated'
  return removedFromCart ? 'removedFromCart' : updatedMsg
}

export const makeChangeNotification = ({
  justAddedToCart,
  removedFromCart,
  intl: { formatMessage },
}) =>
  notifyAction({
    message: formatMessage(
      productBoxMessages[
        getNotificationMsg({
          justAddedToCart,
          removedFromCart,
        })
      ],
    ),
  })

export const getProductGenericInfo = ({
  product: { unitsOfMeasure, nonStock, id, active },
  disablePrices,
  unitOfMeasure,
  units,
}) => {
  const unitOfMeasureObj = find({ unitOfMeasure }, unitsOfMeasure)
  const unitObj = find({ unitOfMeasure }, units)
  const stock = get('stock', unitOfMeasureObj)

  const outOfStock = isOutOfStock({ stock, nonStock })
  const priceShown = !disablePrices && active
  const productPath = generatePath(ROUTES.PRODUCT_DETAILS, { id })

  return {
    outOfStock,
    priceShown,
    productPath,
    stock,
    unitOfMeasureObj,
    unitObj,
  }
}

// returns 1 szt.
export const formatAmount = (unitOfMeasureObj, quantity) =>
  `${parseAmount(
    calculateAmountByQuantity(
      { multiplier: unitOfMeasureObj.multiplier },
      quantity,
    ),
  )} ${unitOfMeasureObj.unitOfMeasureDescription}`

export const formatUnitAmount = (unitOfMeasureObj, amount) =>
  `${amount} ${unitOfMeasureObj.unitOfMeasureDescription}`

export const isAvailableAfter = (product, userData) =>
  dayjs(prop('closestDeliveryTime', product)).isAfter(
    userData.maxDeliveryDate,
    'days',
  )

export const isLoweredPrice = promotion =>
  isDiscountPromoType(promotion) || isSalePromoType(promotion)

export const isProductUnavailable = ({ product, unitOfMeasure }) => {
  const { nonStock, active, licenseMissing } = product
  const { stock } = findProductUnitOfMeasure(product, unitOfMeasure)

  return isOutOfStock({ stock, nonStock }) || !active || licenseMissing
}

export const getBidmarketLabel = features => {
  const bidmarketFeature = features?.find(
    feature => feature.value === OFFER_FEATURES_VALUES.BIDMARKET,
  )

  return bidmarketFeature?.label
}

export const getInCartAmount = unitData =>
  unitData?.inCartQuantity || getOr(1, 'multiplier', unitData)
