import { makeMessages, makeTranslation } from 'utils'

const messages = {
  clickBelow: 'clickBelow',
  ctaBtn: 'ctaBtn',
  alreadySubscribed1: 'alreadySubscribed1',
  alreadySubscribed2: 'alreadySubscribed2',
  alreadySubscribed3: 'alreadySubscribed3',
  successToast: 'successToast',
}

export default makeMessages(
  messages,
  makeTranslation('productDetails.outOfOffer'),
)
