import { useEffect, useMemo, useState } from 'react'
import { useLocation, useMatch } from 'react-router'
import { trackSimpleEvent, EVENT_ORIGIN } from 'services/analytics'

import {
  useGetCategoryTreeQuery,
  useGetCuisinesQuery,
} from 'containers/Categories/rtkApi'

export const useCategoriesDropdownCategorySelect = ({ categories }) => {
  const [selectedCategory, setSelectedCategory] = useState(null)
  const location = useLocation()

  // '*' is a temporary workaround as useMatch doesn't work well with chained optional params
  // https://github.com/remix-run/react-router/discussions/9862
  const { params } = useMatch('/products/:category/*') || {}
  const currentCategory = useMemo(
    () => {
      if (location.search) {
        return null
      }

      const routeCategory = params?.category
      if (!routeCategory) {
        return null
      }

      return categories.find(category => category.id === routeCategory)
    },
    [params, categories],
  )

  useEffect(
    () => {
      setSelectedCategory(currentCategory)
    },
    [currentCategory],
  )

  return {
    selectedCategory,
    setSelectedCategory,
  }
}

export const useCategoriesListSelect = () => {
  const [isCuisinesSelected, setCuisinesSelected] = useState(false)

  const {
    data: categories = [],
    isLoading: isCategoriesLoading,
  } = useGetCategoryTreeQuery()

  const {
    data: cuisines = [],
    isLoading: isCuisinesLoading,
  } = useGetCuisinesQuery(null, {
    skip: !isCuisinesSelected,
  })

  const isLoading = isCuisinesSelected ? isCuisinesLoading : isCategoriesLoading

  return {
    isCuisinesSelected,
    setCuisinesSelected,
    cuisines,
    categories,
    isLoading,
  }
}

export const trackCategoriesDropdownEvent = (event, params) => {
  trackSimpleEvent(event, {
    origin: EVENT_ORIGIN.DROPDOWN_MENU,
    ...params,
  })
}
