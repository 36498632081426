import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react'
import { compose } from 'lodash/fp'
import _trimStart from 'lodash/trimStart'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import { debounce } from 'lodash'
import { reduxForm } from 'redux-form/immutable'
import { useNavigate, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import theme from 'theme'
import { APP_BREAKPOINTS, ROUTES } from 'consts'
import { X, SearchV2, Loader } from 'components/Icons'
import { trackSimpleEvent, NAV_BAR_EVENTS } from 'services/analytics'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popup'
import { HEADER_DROPDOWN_TRANSITIONS } from 'components/Popup/transitions'
import { useOfMinWidth } from 'hooks'

import {
  isSearchFetchingSelector,
  searchResultsSelector,
} from 'containers/components/Search/selectors'
import { searchProductsActions } from 'containers/components/Search/actions'
import { getSearchParams } from './utils'
import messages from './messages'
import SearchSuggest from './SearchSuggest'
import SuggestItem from './SuggestItem'
import {
  SearchForm,
  SearchInput,
  SearchInputContainer,
  SpinnerContainer,
  XContainer,
  SearchBtn,
} from './styles'

export const SEARCH_FORM_NAME = 'search'
export const SEARCH_MIN_LENGTH = 2
const AUTOSUGGEST_MIN_LENGTH = 3

const Search = ({ className, mobileSearchEnabled, setMobileSearchEnabled }) => {
  const dispatch = useDispatch()
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)
  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)
  const { formatMessage } = useIntl()
  const searchResults = useSelector(searchResultsSelector)
  const isSearchFetching = useSelector(isSearchFetchingSelector)
  const location = useLocation()
  const navigate = useNavigate()
  const searchParam = queryString.parse(location.search).search
  const [value, setValue] = useState(searchParam || '')

  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const isFocusedRef = useRef(isSearchFocused)
  useEffect(
    () => {
      isFocusedRef.current = isSearchFocused
    },
    [isSearchFocused],
  )

  const ref = useRef(null)
  useOnClickOutside(ref, () => setDropdownVisible(false))

  const onChangeCallback = useCallback(({ target }) => {
    const nextValue = _trimStart(target.value)
    setValue(nextValue)
  }, [])

  const onChangeCallbackDebounced = useRef(
    debounce(
      nextVal => {
        const search = nextVal.trim().toLowerCase()

        if (search.length < AUTOSUGGEST_MIN_LENGTH) {
          return setDropdownVisible(null)
        }

        return dispatch(
          searchProductsActions.createDelta(
            { search },
            {
              onSuccess: () => {
                if (
                  search.length >= AUTOSUGGEST_MIN_LENGTH &&
                  isFocusedRef.current
                ) {
                  setDropdownVisible(true)
                }
              },
            },
          ),
        )
      },
      500,
      { leading: true, trailing: true },
    ),
  )

  useEffect(
    () => {
      onChangeCallbackDebounced.current(value)
    },
    [value],
  )

  const onSubmitCallback = useCallback(
    e => {
      setDropdownVisible(false)
      e.preventDefault()

      const search = value.trim()

      if (search.length < SEARCH_MIN_LENGTH) {
        return
      }

      const queryParams = getSearchParams(search)
      if (document.activeElement) document.activeElement.blur()
      navigate(`${ROUTES.SEARCH_RESULTS}?${queryParams}`)
    },
    [value, navigate],
  )

  const handleInputFocus = useCallback(
    () => {
      setIsSearchFocused(true)
      if (value.length >= AUTOSUGGEST_MIN_LENGTH) {
        setDropdownVisible(true)
      }
    },
    [value, searchResults],
  )

  const handleInputBlur = useCallback(() => {
    setIsSearchFocused(false)
  }, [])

  const isCloseBtn = !isSearchFetching && (mobileSearchEnabled || !!value)

  const handleCloseBtnClick = () => {
    setValue('')
    mobileSearchEnabled && setMobileSearchEnabled(false)

    if (searchParam) {
      navigate(ROUTES.ALL_PRODUCTS)
    }
  }

  useEffect(
    () => {
      setValue(searchParam || '')
    },
    [location.pathname],
  )

  const offsetValues = useMemo(
    () => {
      if (isDesktop) {
        return { main: 20 }
      }

      if (isTablet) {
        return { main: 8 }
      }

      return { main: 0 }
    },
    [isDesktop, isTablet],
  )

  return (
    <SearchForm ref={ref} className={className} onSubmit={onSubmitCallback}>
      <Popover
        placement="bottom-start"
        open={isDropdownVisible}
        setOpen={setDropdownVisible}
        disableArrow
        offsetValues={offsetValues}
      >
        <PopoverTrigger asChild>
          <SearchInputContainer>
            <SearchBtn
              onClick={onSubmitCallback}
              data-test-id="search_toolbar_icon"
            >
              <SearchV2 />
            </SearchBtn>
            <SearchInput
              {...{ value }}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onClick={() =>
                trackSimpleEvent(NAV_BAR_EVENTS.SEARCH_FIELD_CLICKED)
              }
              maxLength="100"
              placeholder={formatMessage(messages.placeholder)}
              onChange={onChangeCallback}
              isLoading={isSearchFetching}
              data-test-id="search_toolbar"
              autoFocus={mobileSearchEnabled}
            />
            {isSearchFetching && (
              <SpinnerContainer data-test-id="search_field_loader">
                <Loader />
              </SpinnerContainer>
            )}
            {isCloseBtn && (
              <XContainer
                onClick={handleCloseBtnClick}
                data-test-id="search_field_clear"
              >
                <X color={theme.colors.gray5} />
              </XContainer>
            )}
          </SearchInputContainer>
        </PopoverTrigger>

        <PopoverContent
          transitionContainerStyle={{
            transformOrigin: 'top left',
          }}
          transitions={HEADER_DROPDOWN_TRANSITIONS}
          withOverlay
        >
          <SearchSuggest onShowAll={onSubmitCallback}>
            {searchResults.map((product, index) => (
              <SuggestItem
                key={product.id}
                index={index}
                {...{ product }}
                onShowDetails={() => {
                  dispatch(searchProductsActions.clear())
                  setDropdownVisible(false)
                }}
              />
            ))}
          </SearchSuggest>
        </PopoverContent>
      </Popover>
    </SearchForm>
  )
}

export default compose(reduxForm({ form: SEARCH_FORM_NAME }))(Search)
