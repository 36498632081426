import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'

import { PAY_SCENARIO } from 'containers/Orders/consts'
import { CartV2 } from 'components/Icons'
import { ROUTES } from 'consts'

import { VARIANT } from 'views/Dashboard/Invoices/consts'
import { EVENT_ORIGIN, trackGoToCartClick } from 'services/analytics'
import messages from '../../messages'
import Footer from '../Footer'

const CART_STATE = {
  TRANSACTION_LIMIT_EXCEEDED: {
    variant: VARIANT.RED,
    msg: messages.transactionLimitExceeded,
  },
  CREDIT_LIMIT_EXCEEDED: {
    variant: VARIANT.YELLOW,
    msg: messages.creditLimitExceeded,
  },
}

const CartActions = ({ scenario }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const cartState = useMemo(
    () => {
      if (scenario === PAY_SCENARIO.RED) {
        return CART_STATE.TRANSACTION_LIMIT_EXCEEDED
      }

      if (scenario === PAY_SCENARIO.YELLOW) {
        return CART_STATE.CREDIT_LIMIT_EXCEEDED
      }

      return null
    },
    [scenario],
  )

  if (!cartState) return null

  return (
    <Footer
      variant={cartState.variant}
      message={formatMessage(cartState.msg)}
      IconComponent={CartV2}
      btnLabel={formatMessage(messages.showCartBtn)}
      onBtnClick={() => {
        trackGoToCartClick({ origin: EVENT_ORIGIN.DASHBOARD })
        navigate(ROUTES.CART)
      }}
      btnTestID="show-cart-btn"
    />
  )
}

export default CartActions
