import queryString from 'query-string'
import { isEmpty, some } from 'lodash/fp'
import qs from 'qs'

import { DEFAULT_PRODUCT_PAGE_SIZE, ROUTES } from 'consts'
import { FILTER_ITEMS, FILTER_SECTION } from 'components/Filters/consts'
import { getValidPage } from 'components/Pagination/utils'
import { generatePath } from 'react-router'
import _isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import {
  allCategoriesListSelector,
  categoriesListSelector,
  categoryGroupSelector,
  categorySelector,
} from 'containers/Categories/selectors'
import useProductsSectionName from 'containers/Products/Filters/useProductsSectionName'
import { useQueryFilters } from 'hooks'

export { getValidPage }

export function getUrlParams(
  matchParams,
  locationSearch,
  pageSize = DEFAULT_PRODUCT_PAGE_SIZE,
) {
  const { category, group, subgroup } = matchParams
  const { search, current } = queryString.parse(locationSearch)
  const currentPage = Number(current) > 0 ? Number(current) : 1

  return Object.fromEntries(
    Object.entries({
      current: currentPage,
      search,
      pageSize,
      category,
      group,
      subgroup,
    }).reduce((res, [key, value]) => {
      if (value) return [...res, [key, value]]
      return res
    }, []),
  )
}

export const isAnyFilterSelected = selectedFilters => {
  if (!selectedFilters) return false

  return some(filter => !isEmpty(filter) && !filter[FILTER_ITEMS.ALL])(
    Object.values(selectedFilters),
  )
}

export const stringifyQueryParams = paramsObj =>
  qs.stringify(paramsObj, {
    skipNulls: true,
    sort: (a, b) => a.localeCompare(b),
  })

export const useParsedChildGroups = () => {
  const { selectedFilters, searchQuery } = useQueryFilters()
  const filteredCategoriesList = useSelector(categoriesListSelector)
  const allCategoriesList = useSelector(allCategoriesListSelector)
  const { id, parentCategoryId, subgroups = {} } = useSelector(
    categoryGroupSelector,
  )
  const isFilteringApplied = isAnyFilterSelected(selectedFilters) || searchQuery

  const categoriesList = isFilteringApplied
    ? filteredCategoriesList
    : allCategoriesList
  const { id: categoryId, groups = {} } = useSelector(categorySelector)
  const sectionName = useProductsSectionName()

  switch (sectionName) {
    case FILTER_SECTION.CATEGORY:
      return Object.values(groups).map(group => {
        const groupParams = {
          category: categoryId,
          group: group.id,
        }

        return {
          ...group,
          path: generatePath(ROUTES.PRODUCTS, groupParams),
        }
      })
    case FILTER_SECTION.GROUP:
      return Object.values(subgroups).map(subgroup => {
        const subgroupParams = {
          category: parentCategoryId,
          group: id,
          subgroup: subgroup.id,
        }

        return {
          ...subgroup,
          path: generatePath(ROUTES.PRODUCTS, subgroupParams),
        }
      })
    case FILTER_SECTION.ALL:
      return (
        !_isEmpty(categoriesList) &&
        categoriesList?.map(category => ({
          ...category,
          path: generatePath(ROUTES.PRODUCTS, {
            category: category.id,
          }),
        }))
      )
    default:
      return null
  }
}
