import React, { useMemo } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { DATE_TIME_FORMATS } from 'utils/datetime'
import { isOutOfStock, formatPrice, getProductTemplateInclusions } from 'utils'
import { ROUTES } from 'consts'
import ProductImage, {
  PRODUCT_IMAGE_SIZES,
} from 'components/Product/ProductImage'
import {
  findUnitOfMeasureByVariant,
  formatAmount,
  calculateQuantityByAmount,
  isAvailableAfter,
  getBidmarketLabel,
} from 'components/Product/utils'
import {
  ITEM_LIST_NAMES,
  ITEM_LIST_IDS,
  useSelectItemTracking,
} from 'services/analytics'
import cartActions from 'containers/Cart/actions'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { isCartRoute } from 'views/Cart/utils'

import {
  isDiscountPromoType,
  isSalePromoType,
} from 'containers/Promotions/utils'
import PromotionBadge from 'components/Product/PromotionBadge'
import { isProductWithBadgeFeatures } from 'components/Product/Badge/utils'
import SystemTemplateIcon from 'components/Product/SystemTemplateIcon'
import BidmarketLabel from 'components/Product/BidmarketLabel'
import messages from '../messages'
import AddToCartBtn from './AddToCartBtn'
import {
  SuggestedProduct,
  Descr,
  ProductImgContainer,
  InnerContainer,
  Naming,
  Pricing,
  PricingContainer,
  CartBtnContainer,
  PriceText,
  OldPriceText,
  ClampedBadgeRow,
  ProductNameText,
  BrandText,
  IconsContainer,
  PromoTitle,
  AmountText,
  AvailableText,
  PriceTextsContainer,
} from './styledComponents'

const SuggestItem = ({
  product: {
    id,
    unitsOfMeasure,
    nonStock,
    name,
    brand,
    closestDeliveryTime,
    licenseMissing,
    features,
  },
  product,
  onShowDetails,
  index,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const userData = useSelector(userDataSelector)

  const location = useLocation()

  const [unitOfMeasureObj, outOfStock] = useMemo(
    () => {
      const unitObj = findUnitOfMeasureByVariant(unitsOfMeasure)
      const { stock } = unitObj
      const isOut = isOutOfStock({ stock, nonStock })
      return [unitObj, isOut]
    },
    [unitsOfMeasure, nonStock],
  )

  const promotion = product?.promotion
  const isDiscountPromo = isDiscountPromoType(promotion)
  const isSalePromo = isSalePromoType(promotion)
  const hasOfferFeatures = isProductWithBadgeFeatures(product)
  const bidmarketLabel = getBidmarketLabel(features)
  const isLoweredPriceProduct = isDiscountPromo || isSalePromo
  const isSoonAvailable = isAvailableAfter(product, userData)
  const isOldPrice = !isSoonAvailable && isLoweredPriceProduct
  const isInactive = outOfStock || isSoonAvailable
  const [, isInSystemTemplate] = getProductTemplateInclusions(unitOfMeasureObj)

  const trackSelectItem = useSelectItemTracking({
    product,
    itemListName: ITEM_LIST_NAMES.SEARCH,
    itemListId: ITEM_LIST_IDS.SEARCH,
    unitOfMeasure: unitOfMeasureObj.unitOfMeasure,
    index,
  })
  const handleClick = () => {
    trackSelectItem()
    onShowDetails(product)
    navigate(generatePath(ROUTES.PRODUCT_DETAILS, { id: product.id }), {
      state: {
        itemListId: ITEM_LIST_IDS.SEARCH,
        itemListName: ITEM_LIST_NAMES.SEARCH,
      },
    })
  }

  return (
    <SuggestedProduct
      onClick={handleClick}
      data-test-id={`suggest-item#${product.id}`}
    >
      <InnerContainer>
        <ProductImgContainer>
          <ProductImage
            {...{ product }}
            size={PRODUCT_IMAGE_SIZES.S}
            outOfStock={isInactive}
          />
        </ProductImgContainer>

        <Descr>
          <Naming>
            <div>
              <BrandText $isInactive={isInactive} data-test-id="suggest-item-brand">
                {brand}
              </BrandText>
              <ProductNameText $isInactive={isInactive} data-test-id="suggest-item-name">
                {name}
              </ProductNameText>
              <IconsContainer>
                {isInSystemTemplate && (
                  <SystemTemplateIcon productId={id} iconSize={12} />
                )}
                {!!bidmarketLabel && (
                  <BidmarketLabel label={bidmarketLabel} separatorSize={0} />
                )}
              </IconsContainer>
            </div>

            {(promotion || hasOfferFeatures) && (
              <ClampedBadgeRow>
                <PromotionBadge product={product} productFeaturesLimit={1} />
                {!!promotion && <PromoTitle>{promotion.title}</PromoTitle>}
              </ClampedBadgeRow>
            )}
          </Naming>

          <PricingContainer>
            <Pricing>
              <AmountText data-test-id="suggest-item-qty-and-unit">
                {formatAmount(unitOfMeasureObj, unitOfMeasureObj.quantity)}
              </AmountText>
              <PriceTextsContainer>
                <PriceText $isInactive={isInactive} data-test-id="suggest-item-price">
                  {formatPrice(
                    isLoweredPriceProduct
                      ? promotion.reward.priceNet
                      : unitOfMeasureObj.priceNet,
                  )}
                </PriceText>
                {isOldPrice && (
                  <OldPriceText>
                    {formatPrice(unitOfMeasureObj.priceNet)}
                  </OldPriceText>
                )}
              </PriceTextsContainer>
            </Pricing>

            {isSoonAvailable && (
              <AvailableText>
                {formatMessage(messages.availableFrom, {
                  date: dayjs(closestDeliveryTime).format(
                    DATE_TIME_FORMATS.SHORT_DATE,
                  ),
                })}
              </AvailableText>
            )}
          </PricingContainer>
        </Descr>

        <CartBtnContainer isHidden={licenseMissing}>
          <AddToCartBtn
            {...{ isInactive }}
            unitOfMeasure={unitOfMeasureObj}
            onAddToCart={ev => {
              ev.stopPropagation()
              ev.preventDefault()
              dispatch(
                cartActions.createDelta({
                  product,
                  unit: unitOfMeasureObj.unitOfMeasure,
                  justAddedToCart: !unitOfMeasureObj.unitOfMeasure
                    .inCartQuantity,
                  quantity: calculateQuantityByAmount(
                    unitOfMeasureObj,
                    unitOfMeasureObj.multiplier,
                  ),
                  callback: () => {
                    if (isCartRoute(location)) {
                      dispatch(cartActions.delta())
                    }
                  },
                  itemListId: ITEM_LIST_IDS.SEARCH,
                  itemListName: ITEM_LIST_NAMES.SEARCH,
                }),
              )
            }}
            onDeleteFromCart={ev => {
              ev.stopPropagation()
              ev.preventDefault()
              dispatch(
                cartActions.deleteDelta({
                  product,
                  productId: id,
                  unitOfMeasure: unitOfMeasureObj.unitOfMeasure,
                  itemListId: ITEM_LIST_IDS.SEARCH,
                  itemListName: ITEM_LIST_NAMES.SEARCH,
                }),
              )
            }}
          />
        </CartBtnContainer>
      </InnerContainer>
    </SuggestedProduct>
  )
}

export default SuggestItem
