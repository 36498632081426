import React, { useMemo } from 'react'

import { HIGHLIGHT_MODE } from './consts'
import { useCuisineFeaturesTitle } from './utils'

const Title = ({ title: { firstLine, secondLine, mode } }) => {
  const cuisineTitle = useCuisineFeaturesTitle()

  // using this instead of css as it's easier to test
  const firstLineCapitalized = useMemo(() => {
    if (!firstLine) {
      return null
    }

    return firstLine.charAt(0).toUpperCase() + firstLine.slice(1)
  })

  return (
    <div className="mb-4">
      {cuisineTitle && (
        <h1 className="text-h1 text-grey-700">{cuisineTitle}</h1>
      )}
      <h1 className="text-h1">
        {!!firstLineCapitalized && (
          <span
            className={
              mode === HIGHLIGHT_MODE.FILTER_FIRST
                ? 'text-grey-700'
                : 'text-blue-900'
            }
          >
            {firstLineCapitalized}
          </span>
        )}
        {!!secondLine && (
          <span
            className={
              mode === HIGHLIGHT_MODE.FILTER_FIRST
                ? 'text-blue-900'
                : 'text-grey-700'
            }
          >
            {secondLine}
          </span>
        )}
      </h1>
    </div>
  )
}

export default Title
