import React from 'react'

import { cn } from 'utils'
import { CircleAlert } from 'components/Icons'

const Switch = ({
  id,
  name = id,
  value,
  readonly,
  testId,
  isError,
  errorText,
  handleChange,
  className,
  label,
}) => (
  <div className="flex flex-col gap-2">
    <div className={cn('flex gap-4 py-2 items-center', className)}>
      <label className="relative flex w-12 h-6">
        <input
          id={id}
          name={name}
          className="w-0 h-0 opacity-0"
          type="checkbox"
          checked={value}
          onChange={handleChange}
          data-test-id={testId}
        />
        <div
          className={cn(
            'p-1 rounded-lg w-12 duration-300 bg-grey-400 cursor-pointer',
            {
              'bg-green-600': value,
              'cursor-default bg-green-100': readonly,
              'bg-red-100': isError,
            },
          )}
        >
          <div
            className={cn('h-4 w-6 rounded bg-white duration-300 shadow-sm', {
              'translate-x-4': value,
              'shadow-none': !readonly,
            })}
          />
        </div>
      </label>

      {label && <span className="text-sm leading-6 font-medium">{label}</span>}
    </div>

    {isError && (
      <div className="flex gap-2">
        <CircleAlert />
        <span className="text-red-700 text-label-11">{errorText}</span>
      </div>
    )}
  </div>
)

export default Switch
