import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { propOr } from 'lodash/fp'
import { useNavigationType, useLocation } from 'react-router'
import usePrevious from 'use-previous'

import ModalWrapper from 'components/modal/ModalWrapper'
import ModalBody from 'components/modal/ModalBody'
import ModalClose from 'components/modal/ModalClose'
// TODO refactor the way modals are stored/configured
import { modalsMap, modalsProps } from 'hocs/withModal'

import { closeModal } from './actions/modal'
import { modalStackSelector, modalDisplaySelector } from './selectors'
import { ModalGlobalStyle } from './styledComponents'

const Modal = ({ modal, isOpened }) => {
  const dispatch = useDispatch()
  const Child = modalsMap[modal.type]
  const modalConfig = modalsProps[modal.type]

  const closeOpenedModal = (modalType, resolve = () => {}) => () => {
    dispatch(closeModal(modalType))
    resolve()
  }

  const {
    data: { size, heading, hideHeader, bodyClassName, ...rest },
  } = modal

  const location = useLocation()
  const prevLocation = usePrevious(location)
  const navigationType = useNavigationType()
  useEffect(
    () => {
      if (
        isOpened &&
        navigationType === 'POP' &&
        prevLocation &&
        prevLocation !== location
      ) {
        closeOpenedModal(modal.type, modal.resolve)()
      }
    },
    [location, navigationType, prevLocation],
  )

  const RootComponent = propOr(ModalBody, 'rootComponent', modalConfig)

  return (
    <RootComponent
      size={size}
      key={modal.type}
      className={bodyClassName}
      closeModal={closeOpenedModal(modal.type, modal.resolve)}
    >
      {!hideHeader && (
        <ModalClose
          heading={heading}
          onClick={closeOpenedModal(modal.type, modal.resolve)}
        />
      )}
      <Child
        modal={{
          ...rest,
          type: modal.type,
          closeModal: closeOpenedModal(modal.type, modal.resolve),
        }}
      />
    </RootComponent>
  )
}

const ModalBase = () => {
  const isOpened = useSelector(modalDisplaySelector)
  const stack = useSelector(modalStackSelector)

  return (
    <ModalWrapper isOpened={isOpened}>
      {isOpened && <ModalGlobalStyle />}
      {stack.map(modal => (
        <Modal key={modal.type} modal={modal} isOpened={isOpened} />
      ))}
    </ModalWrapper>
  )
}

export default ModalBase
