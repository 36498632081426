import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { SecondaryBtn } from 'components/Button'
import { closeModal } from 'containers/App/actions/modal'
import { XNormal } from 'components/Icons'

import { useRichFormatMessage } from 'utils'
import { DELIVERY_EXPIRED_MODAL } from '../../../containers/App/modalTypes'
import messages from '../messages'

import {
  TitleContainer,
  Title,
  Msg,
  CtaBtnContainer,
  CloseIconContainer,
  ImgContainer,
} from './styles'

const DeliveryExpiredModal = () => {
  const dispatch = useDispatch()
  const formatRichMessage = useRichFormatMessage()

  const closeModalCallback = useCallback(() => {
      dispatch(closeModal(DELIVERY_EXPIRED_MODAL))
    },
    [dispatch],
  )

  return (
    <div>
      <TitleContainer>
        <Title>
          <FormattedMessage {...messages.deliveryExpTitle} />
        </Title>

        <CloseIconContainer onClick={closeModalCallback}>
          <XNormal />
        </CloseIconContainer>
      </TitleContainer>

      <Msg>{formatRichMessage(messages.deliveryExpMsg)}</Msg>

      <ImgContainer />

      <CtaBtnContainer>
        <SecondaryBtn $fullWidth onClick={closeModalCallback}>
          <FormattedMessage {...messages.deliveryExpCtaBtn} />
        </SecondaryBtn>
      </CtaBtnContainer>
    </div>
  )
}

export default DeliveryExpiredModal
export { ModalRoot as DeliveryExpiredModalRoot } from './styles'
