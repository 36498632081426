import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Flex } from '@rebass/grid'
import { compose } from 'lodash/fp'
import { useParams } from 'react-router'
import usePrevious from 'use-previous'
import { useDispatch, useSelector } from 'react-redux'

import { withSpinner } from 'hocs'
import injectSaga from 'utils/injectSaga'
import saga from 'containers/Campaigns/sagas'
import {
  CAMPAIGNS_REDUCER_NAME,
  CAMPAIGN_DETAILS_REDUCER_NAME,
  CAMPAIGN_PRODUCTS_REDUCER_NAME,
} from 'containers/Campaigns'
import {
  PROMO_GROUP_REDUCER_NAME,
  PROMOS_REDUCER_NAME,
} from 'containers/Promotions/consts'
import Pagination from 'components/Pagination'
import { FooterNav } from 'views/Products/styledComponents/FooterNav'
import Back from 'components/Back'
import { PageTitle } from 'components/Text'
import { withPageView, SCREENS, ITEM_LIST_NAMES } from 'services/analytics'
import ProductBox from 'components/Product/ProductBox'

import {
  campaignDetailsActions,
  campaignProductsActions,
} from 'containers/Campaigns/actions'
import {
  campaignDetailsSelector,
  campaignProductsItemsSelector,
  campaignProductsPaginationSelector,
} from 'containers/Campaigns/selectors'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import {
  DESKTOP_HEIGHT,
  MOBILE_HEIGHT,
} from 'containers/App/LoggedInLayout/Header/styles'
import messages from './messages'
import {
  Wrapper,
  Header,
  TopSection,
  ImgWrapper,
  RightSection,
  Button,
  Description,
  ProductsListWrapper,
  HeaderWrapper,
} from './styledComponents'

const CampaignDetails = () => {
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const productsHeaderEl = useRef(null)
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)
  const headerHeight = isDesktop ? DESKTOP_HEIGHT : MOBILE_HEIGHT

  const products = useSelector(campaignProductsItemsSelector)
  const { currentPage, totalPages } = useSelector(
    campaignProductsPaginationSelector,
  )
  const prevPage = usePrevious(currentPage)

  const { title, pictures = [], urls = [], description } = useSelector(
    campaignDetailsSelector,
  )

  useEffect(() => {
    dispatch(campaignDetailsActions.delta(id))
    dispatch(campaignProductsActions.delta({ id, page: 1 }))

    return () => {
      dispatch(campaignDetailsActions.clear())
      dispatch(campaignProductsActions.clear())
    }
  }, [])

  useEffect(
    () => {
      if (prevPage && prevPage !== currentPage && productsHeaderEl.current) {
        window.scrollTo(0, productsHeaderEl.current.offsetTop - headerHeight)
      }
    },
    [currentPage, prevPage],
  )

  return (
    <Wrapper>
      <HeaderWrapper alignItems="center">
        <Back />
        <Header>{title}</Header>
      </HeaderWrapper>

      <TopSection>
        {!!pictures.length && (
          <ImgWrapper>
            <img alt={title} src={pictures[0]} />
          </ImgWrapper>
        )}
        <RightSection withDivider={!!pictures[0]}>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
          {urls.map(({ name, url }) => (
            <Button key={url} onClick={() => window.open(url)}>
              {name}
            </Button>
          ))}
        </RightSection>
      </TopSection>

      {!!products.length && (
        <Flex flexDirection="column">
          <PageTitle ref={productsHeaderEl}>
            {formatMessage(messages.recommendedProducts)}
          </PageTitle>
          <ProductsListWrapper>
            {products.map((product, index) => (
              <ProductBox
                itemListName={ITEM_LIST_NAMES.CAMPAIGNS}
                itemListId={id}
                key={product.id}
                {...product}
                {...{ index, product }}
              />
            ))}
          </ProductsListWrapper>
          <FooterNav>
            <Pagination
              current={Number(currentPage)}
              onChange={newPage =>
                dispatch(campaignProductsActions.delta({ id, page: newPage }))
              }
              totalPages={totalPages}
            />
          </FooterNav>
        </Flex>
      )}
    </Wrapper>
  )
}

export default compose(
  injectSaga({ key: CAMPAIGNS_REDUCER_NAME, saga }),
  withPageView(SCREENS.CAMPAIGN_DETAILS),
  withSpinner([
    [CAMPAIGNS_REDUCER_NAME, CAMPAIGN_DETAILS_REDUCER_NAME],
    [CAMPAIGNS_REDUCER_NAME, CAMPAIGN_PRODUCTS_REDUCER_NAME],
    [PROMOS_REDUCER_NAME, PROMO_GROUP_REDUCER_NAME],
  ]),
)(CampaignDetails)
