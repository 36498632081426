import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { ROUTES } from 'consts'
import {
  isApiErrorOfCode,
  extractFirstError,
  useRichFormatMessage,
} from 'utils'
import { useCustomFormikHandlers } from 'hooks'
import { withSpinner } from 'hocs'

import {
  confirmResetPasswordActions,
  validateResetPasswordTokenActions,
} from 'containers/Profile/ResetPassword/actions'
import {
  resetPasswordConfirmErrorSelector,
  resetPasswordTokenErrorSelector,
  resettingPasswordLoginSelector,
  isResetPasswordTokenValidatedSelector,
} from 'containers/Profile/ResetPassword/selectors'
import {
  RESET_PASSWORD_CONFRIRM_REDUCER_NAME,
  RESET_PASSWORD_REDUCER_NAME,
} from 'containers/Profile/ResetPassword/consts'
import { VALIDATE_ERRORS } from 'containers/Auth/consts'
import { resendPasswordResetActions } from 'containers/Auth/actions'

import InfoPanel from 'views/Profile/ResetPassword/InfoPanel'
import FormInputField from 'components/Fields/FormInputField'
import { ArrowLongRight } from 'components/Icons'
import NotificationWrapper from 'components/NotificationWrapper'

import theme from 'theme'
import {
  Divider,
  FormSection,
  InputFieldContainer,
  InputLabel,
  SubmitButton,
} from '../styledComponents'
import resetConfirmSchema from './validationSchema'
import messages from '../messages'

const ResetPasswordConfirm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token } = useParams()
  const formatMessage = useRichFormatMessage()
  const [isResetConfirmed, setResetConfirmed] = useState(false)
  const [isResent, setIsResent] = useState(false)

  const resetPasswordTokenError = useSelector(resetPasswordTokenErrorSelector)
  const resettingPasswordLogin = useSelector(resettingPasswordLoginSelector)
  const isResetPasswordTokenValidated = useSelector(
    isResetPasswordTokenValidatedSelector,
  )

  useEffect(() => {
    dispatch(validateResetPasswordTokenActions.createDelta({ token }))

    return () => {
      dispatch(confirmResetPasswordActions.clear())
      dispatch(validateResetPasswordTokenActions.clear())
    }
  }, [])

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useCustomFormikHandlers({
    initialValues: {
      password: '',
    },
    onSubmit: fieldValues => {
      dispatch(
        confirmResetPasswordActions.createDelta(
          { ...fieldValues, token },
          { successCallback: () => setResetConfirmed(true) },
        ),
      )
    },
    validationSchema: resetConfirmSchema,
  })

  const confirmError = useSelector(resetPasswordConfirmErrorSelector)
  const serverError = extractFirstError(confirmError)

  useEffect(
    () => {
      if (serverError) {
        dispatch(confirmResetPasswordActions.clear())
      }
    },
    [values],
  )

  if (!isResetPasswordTokenValidated) return null

  if (isApiErrorOfCode(VALIDATE_ERRORS.INVALID)(resetPasswordTokenError)) {
    return (
      <InfoPanel
        title={formatMessage(messages.linkInvalidTitle)}
        subtitle={formatMessage(messages.linkInvalidMessage)}
        hasContacts
      />
    )
  }

  if (isResent) {
    return (
      <InfoPanel
        title={formatMessage(messages.resend)}
        subtitle={formatMessage(messages.resendDescription)}
        btnText={formatMessage(messages.toLogin)}
        onBtnClick={() => navigate(ROUTES.LOGIN)}
      />
    )
  }

  if (isApiErrorOfCode(VALIDATE_ERRORS.EXPIRED)(resetPasswordTokenError)) {
    return (
      <InfoPanel
        title={formatMessage(messages.linkExpiredTitle)}
        subtitle={formatMessage(messages.linkExpiredMessage)}
        btnText={formatMessage(messages.resendResetLink)}
        hasContacts
        onBtnClick={e => {
          e.preventDefault()
          dispatch(
            resendPasswordResetActions.delta(
              { token },
              { successCallback: () => setIsResent(true) },
            ),
          )
        }}
      />
    )
  }

  if (isApiErrorOfCode(VALIDATE_ERRORS.ALREADY_USED)(resetPasswordTokenError)) {
    return (
      <InfoPanel
        title={formatMessage(messages.linkAlreadyUsedTitle)}
        subtitle={formatMessage(messages.linkAlreadyUsedMessage)}
        btnText={formatMessage(messages.toLogin)}
        onBtnClick={() => navigate(ROUTES.LOGIN)}
        hasContacts
      />
    )
  }

  return isResetConfirmed ? (
    <InfoPanel
      title={formatMessage(messages.confirmDoneTitle)}
      subtitle={formatMessage(messages.confirmDoneSubtitle)}
      btnText={formatMessage(messages.confirmDoneCtaBtn)}
      onBtnClick={() => navigate(ROUTES.LOGIN)}
    />
  ) : (
    <NotificationWrapper
      title={formatMessage(messages.confirmTitle)}
      subtitle={formatMessage(messages.confirmSubtitle, {
        login: resettingPasswordLogin,
      })}
    >
      <FormSection onSubmit={handleSubmit}>
        <Divider />
        <InputFieldContainer>
          <InputLabel>{formatMessage(messages.password)}</InputLabel>
          <FormInputField
            name="password"
            type="password"
            testId="reset_password_new_password"
            value={values.password}
            touched={touched.password}
            placeholder={formatMessage(messages.password)}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errorText={serverError?.message || formatMessage(errors.password)}
            hint={formatMessage(messages.newPasswordHint)}
            isError={serverError || (touched.password && errors.password)}
          />
        </InputFieldContainer>
        <Divider />
        <SubmitButton type="submit" data-test-id="temp-btn">
          {formatMessage(messages.confirmCtaBtn)}
          <ArrowLongRight color={theme.colors.white} />
        </SubmitButton>
      </FormSection>
    </NotificationWrapper>
  )
}

export default withSpinner([
  [RESET_PASSWORD_REDUCER_NAME, RESET_PASSWORD_CONFRIRM_REDUCER_NAME],
])(ResetPasswordConfirm)
