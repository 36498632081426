import React from 'react'
import { useIntl } from 'react-intl'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { ROUTES } from 'consts'
import { formatPrice } from 'utils'
import { P } from 'components/Text'
import { Card } from 'components/Icons'
import { INVOICE_STATUS } from 'containers/Invoices/consts'
import { EVENT_ORIGIN, trackPayInvoices } from 'services/analytics'
import { userDataSelector } from 'containers/UserInfo/selectors'

import messages from '../../../messages'
import { INVOICES_PAY_BUTTON_CONFIG } from '../../consts'

import { Container, TotalContainer, GrossLabel, PayDescription } from './styles'

const PayInvoicesButton = ({
  invoicesTotal,
  tradeLimitData,
  isGreenClient,
}) => {
  const navigate = useNavigate()
  const btnConfig = INVOICES_PAY_BUTTON_CONFIG[tradeLimitData.paymentColorId]
  const { customerNo, storeId } = useSelector(userDataSelector)
  const { formatMessage } = useIntl()

  if (!btnConfig) return null

  const { bgColor, bgHoverColor, txtColor } = btnConfig
  const handleClick = () => {
    trackPayInvoices({ storeId, customerNo, origin: EVENT_ORIGIN.CART })
    navigate(
      `${ROUTES.INVOICES.ROOT}?${queryString.stringify({
        preselectStatus: isGreenClient
          ? [INVOICE_STATUS.NOT_PAID, INVOICE_STATUS.NOT_PAID_EXPIRED]
          : INVOICE_STATUS.NOT_PAID_EXPIRED,
      })}`,
    )
  }

  return (
    <Container
      onClick={handleClick}
      bg={bgColor}
      className="pay-invoices"
      {...{ bgHoverColor }}
    >
      <PayDescription>
        <P bold color={txtColor}>
          {isGreenClient
            ? formatMessage(messages.payBills)
            : formatMessage(messages.payOverdueBills)}
        </P>
      </PayDescription>
      <TotalContainer>
        <GrossLabel color={txtColor} style={{ whiteSpace: 'nowrap' }}>
          {formatMessage(messages.grossSum)}
        </GrossLabel>

        <P bold color={txtColor}>
          {isGreenClient
            ? formatPrice(invoicesTotal)
            : formatPrice(tradeLimitData.overdueAmount)}
        </P>
      </TotalContainer>

      <Card color={txtColor} />
    </Container>
  )
}

export default PayInvoicesButton
