import { matchPath } from 'react-router'

import { FLATTENED_ROUTES } from 'consts'

// value: url/route path
// mainly for root modal routes, path values should still work
// TODO: find a better way to get routes in modals
export const findRouterPath = value =>
  Object.values(FLATTENED_ROUTES).find(route =>
    matchPath({ path: route, end: true }, value),
  )
