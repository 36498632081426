import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { debounce } from 'lodash'

import { trackSearchIconClicked } from 'services/analytics/template'
import SearchInput from 'components/Input/Search'

const SEARCH_MIN_LENGTH = 3

const Search = ({ filteringSettings, updateFilteringSettings, type }) => {
  const { id } = useParams()
  const searchRef = useRef(null)

  const searchItems = search => {
    updateFilteringSettings({
      search,
      filters: {},
    })
  }

  const [value, setValue] = useState('')
  const [isActive, setIsActive] = useState(false)

  const inputRef = searchRef.current?.inputRef
  const containerRef = searchRef.current?.containerRef

  const searchButtonHandler = () => {
    if (value) return
    setIsActive(!isActive)
  }

  const handleInputBlur = e => {
    // don't blur if clicked element is inside container
    const clickedEl = e.relatedTarget
    if (containerRef?.contains(clickedEl)) {
      return
    }

    if (!value) setIsActive(false)
  }

  useEffect(
    () => {
      if (isActive) {
        inputRef?.focus()
        trackSearchIconClicked(id, type)
      } else {
        inputRef?.blur()
      }
    },
    [isActive],
  )

  const resetInput = () => {
    setValue('')
    setIsActive(false)
  }

  useEffect(resetInput, [id])

  useEffect(
    () => {
      if (filteringSettings.search === '') resetInput()
    },
    [filteringSettings.search],
  )

  const validateSearch = useCallback(
    currentValue => {
      if (
        currentValue.length < SEARCH_MIN_LENGTH &&
        filteringSettings.search.length < SEARCH_MIN_LENGTH
      ) {
        return
      }
      if (currentValue.length >= SEARCH_MIN_LENGTH) searchItems(currentValue)
      else searchItems('')
    },
    [searchItems, filteringSettings.search],
  )

  const debouncedSearch = useMemo(() => debounce(validateSearch, 500), [
    validateSearch,
  ])

  const clearButtonHandler = () => {
    setValue('')
    debouncedSearch('')
    setIsActive(!isActive)
  }

  const handleInputChange = val => {
    setValue(val)
    debouncedSearch(val)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      debouncedSearch.cancel()
      validateSearch(e.target.value)
    }
  }

  return (
    <SearchInput
      ref={searchRef}
      isActive={isActive}
      containerTestId="template-search-container"
      onSearchBtnClick={searchButtonHandler}
      searchBtnTestId="template-search-button"
      inputTestId={`template-search-input-${isActive ? 'active' : 'inactive'}`}
      value={value}
      onKeyDown={handleKeyDown}
      onInputChange={handleInputChange}
      onInputBlur={handleInputBlur}
      clearBtnTestId="template-search-clear"
      onClearBtnClick={clearButtonHandler}
    />
  )
}

export default Search
