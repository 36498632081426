import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

import { ORDERS_ROUTES } from 'views/Orders/consts'
import MainPageBreadcrumbs from 'components/Layout/Breadcrumbs/MainPage'

import messages from '../messages'

const Breadcrumbs = () => {
  const { formatMessage } = useIntl()

  const breadcrumbs = [
    {
      path: generatePath(ORDERS_ROUTES.LIST),
      title: formatMessage(messages.orders),
    },
  ]

  return <MainPageBreadcrumbs breadcrumbs={breadcrumbs} />
}

export default Breadcrumbs
