import React from 'react'
import { useIntl } from 'react-intl'

import InfoPanel from 'views/InfoPanel'
import messages from './messages'

const Succeed = () => {
  const { formatMessage } = useIntl()
  return (
    <InfoPanel header={formatMessage(messages.success)}>
      <span className="text-base md:text-lg leading-7">
        {formatMessage(messages.successDescription)}
      </span>
    </InfoPanel>
  )
}

export default Succeed
