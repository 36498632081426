import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, generatePath, useMatch } from 'react-router'

import { ROUTES } from 'consts'
import { cn } from 'utils'
import { getAuthToken } from 'containers/Auth/tokenStorage'
import { getPublicCategories } from '../utils'
import InfoIcon from './assets/info-icon.svg'
import Item from './Item'
import CategoryItem from './CategoryItem'

import messages from '../messages'

const CategoriesList = ({ categories, className }) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { pathname } = useMatch(ROUTES.KNOWLEDGE_BASE) || {}

  const token = getAuthToken()
  const categoriesList = useMemo(
    () => {
      if (!token) return getPublicCategories(categories)

      return categories
    },
    [token, categories],
  )

  const mainPagePath = generatePath(ROUTES.KNOWLEDGE_BASE)

  return (
    <div className={cn('flex flex-col gap-3', className)}>
      {token && (
        <Item
          title={formatMessage(messages.mainTitle)}
          icon={InfoIcon}
          onClick={() => navigate(mainPagePath)}
          isHighlighted={mainPagePath === pathname}
        />
      )}
      {categoriesList?.map(category => (
        <CategoryItem key={category.title} category={category} />
      ))}
    </div>
  )
}

export default CategoriesList
