import styled from 'styled-components'
import { Link } from 'react-router'

import theme from 'theme'

import {
  getColors,
  getFonts,
  getTextSizes,
  getLineHeights,
  getFontWeights,
} from '../helpers/theme-selectors'
import { textStyles } from './styles'

// prettier-ignore
export const BaseText = styled.span`
  ${textStyles};
  font-family: ${props => getFonts(props).base};

  ${props => props.centered && `
    text-align: center;
  `}

  ${props => props.isSuccess && `
    color: ${getColors(props).secondary};
  `}
  ${props => props.isError && `
    color: ${getColors(props).error};
  `}
  ${props => props.isWarningLight && `
    color: ${getColors(props).orange};
  `}
  ${props => props.isInfo && `
    color: ${getColors(props).info};
  `}
  ${props => props.faded && `
    color: ${getColors(props).gray4};
  `}
  ${props => props.color && `
    color: ${props.color};
  `}
  ${p => p.fontSize && `font-size: ${p.fontSize}`};
  ${p => p.uppercase && `text-transform: uppercase;`};

  b {
    font-weight: ${props => getFontWeights(props).bold};
  }
`

export const BaseTextV2 = styled(BaseText)`
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.primary};
  line-height: ${({ isSmall: { lineHeight } = {} }) =>
    lineHeight ? '20px' : '24px'};
  font-size: ${({ isSmall: { fontSize } = {} }) =>
    fontSize ? '12px' : '13px'};
  ${({ $fontWeight }) => $fontWeight && `font-weight: ${$fontWeight}`};
  ${({ $lineHeight }) => $lineHeight && `line-height: ${$lineHeight}`};
  ${({ $fontSize }) => $fontSize && `font-size: ${$fontSize}`};
  ${({ $color }) => $color && `color: ${$color}`};
`

export const BaseTextV2Medium = styled(BaseTextV2)`
  font-weight: ${theme.fontWeights.medium};
`

export const BaseTextV2Bold = styled(BaseTextV2)`
  font-weight: ${theme.fontWeights.bold};
`

export const BaseTextV2Secondary = styled(BaseTextV2)`
  color: ${theme.colors.lightGrey4};
`

export const TinyTextV2 = styled(BaseTextV2)`
  font-size: 11px;
  line-height: 16px;
`

export const TinyTextV2Medium = styled(TinyTextV2)`
  font-weight: ${theme.fontWeights.medium};
`

export const TinyTextV2Bold = styled(TinyTextV2)`
  font-weight: ${theme.fontWeights.bold};
`

export const DetailTextV2 = styled(BaseTextV2)`
  font-size: 10px;
  line-height: 14px;
`

export const TertiaryTextV2 = styled(BaseTextV2)`
  font-size: ${theme.textSizes.m};
`

export const TertiaryTextV2Medium = styled(TertiaryTextV2)`
  font-weight: ${theme.fontWeights.medium};
`

export const TinyLabel = styled(BaseTextV2)`
  font-size: 8px;
  line-height: 8px;
`

export const TinyTextV2Secondary = styled(TinyTextV2)`
  color: ${theme.colors.lightGrey4};
`

export const LargeTextV2 = styled(BaseTextV2)`
  font-size: ${theme.textSizes.l};
`

export const LargeTextV2Medium = styled(LargeTextV2)`
  font-weight: ${theme.fontWeights.medium};
`

export const P = styled(BaseText).attrs({ as: 'p' })`
  font-size: ${props => getTextSizes(props).l}; // 16px
  line-height: ${props => getLineHeights(props).md};
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
`

export const PPrimary = styled(P)`
  color: ${props => props.color || getColors(props).primary};
`
export const PSecondary = styled(P)`
  color: ${props => getColors(props).gray4};
`
export const PSuccess = styled(P)`
  color: ${props => getColors(props).secondary};
`
export const PSuccessDark = styled(P)`
  color: ${props => getColors(props).secondary};
`

export const ButtonText = styled(BaseText)`
  font-size: ${props => getTextSizes(props).l};
  line-height: ${props => getLineHeights(props).md};
`

export const InlineText = styled(BaseText)`
  font-size: ${props => getTextSizes(props).l};
  line-height: ${props => getLineHeights(props).md};
`

export const SubText = styled(BaseText)`
  font-size: ${props => getTextSizes(props).l};
  line-height: ${props => getLineHeights(props).md};
`

export const A = styled.a`
  color: ${props => getColors(props).primary};
  font-size: ${props => getTextSizes(props).l};
  line-height: ${props => getLineHeights(props).md};
  font-weight: ${props => getFontWeights(props).bold};
  text-decoration: none;
`

export const RegularText = styled(BaseText).attrs({ as: 'div' })`
  font-size: ${props => getTextSizes(props).m}; // 14px
  line-height: ${props => getLineHeights(props).md}; // 20px
`

// prettier-ignore
export const SecondaryText = styled(BaseText)`
  font-size: ${props => getTextSizes(props).sm}; // 13px
  line-height: ${props => getLineHeights(props).md};
  color: ${props => props.color || getColors(props).gray3};
  letter-spacing: 0.02em;
`
export const SecondaryTextBlack = styled(SecondaryText)`
  color: ${props => getColors(props).black};
`
export const SecondaryTextPrimary = styled(SecondaryText)`
  color: ${props => getColors(props).primary};
`
export const SecondaryTextSuccess = styled(SecondaryText)`
  color: ${props => getColors(props).secondary};
`
export const SecondaryTextError = styled(SecondaryText)`
  color: ${props => getColors(props).error};
`
export const SecondaryTextFaded = styled(SecondaryText)`
  color: ${props => getColors(props).gray4};
`

export const TinyText = styled(BaseText).attrs({ as: 'div' })`
  font-size: ${props => getTextSizes(props).xs}; // 12px
  color: ${props => props.color || getColors(props).primary};
  line-height: ${props => getLineHeights(props).lg}; // 18px
`

export const TinyTextFaded = styled(TinyText)`
  color: ${props => getColors(props).battleshipGrey};
`

export const TinyTextHigh = styled(TinyText)`
  line-height: 20px;
`

// prettier-ignore
export const TertiaryText = styled(BaseText)`
  font-size: ${props => getTextSizes(props).m}; // 14px
  line-height: 20px;
  color: ${props => props.color || getColors(props).primary};
  letter-spacing: 0;
`

// prettier-ignore
export const BoldInfo = styled(BaseText)`
  font-size: ${props => props.fontSize || getTextSizes(props).xl}; // 20px
  font-weight: ${props => getFontWeights(props).bold};
  line-height: ${props => getLineHeights(props).md};
  color: ${props => props.color || getColors(props).primary};
  letter-spacing: 0;
`

export const Caption = styled(BaseText)`
  font-size: 11px;
  line-height: 20px;
`

export const SubheaderV2 = styled(BaseText)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`

export const ControlLabel = styled(BaseText)`
  font-family: ${theme.fonts.primary};
  font-size: 12px;
  line-height: 24px;
`

export const LegalText = styled(DetailTextV2)`
  color: ${theme.colors.lightGrey4};
`

export const LegalTextLink = styled(Link)`
  font-size: 10px;
  line-height: 14px;
  color: ${theme.colors.lightGrey4};
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
`
