import { useEffect } from 'react'
import { useContentful } from 'utils/contentful'

const parseArticlesData = articles =>
  articles.map(({ fields, sys }) => ({
    title: fields.title,
    desc: fields.description,
    articleId: sys.id,
    body: fields.body,
  }))

const parseCategoriesData = categories =>
  categories.map(({ fields, sys }) => ({
    articles: parseArticlesData(fields.articles),
    title: fields.data.fields.title,
    icon: fields.data.fields.menuIcon.fields.file.url,
    categoryId: sys.id,
    img: fields.data.fields.backgroundImage.fields.file.url,
    theme: fields.data.fields.theme.fields,
    isPublic: fields.data.fields.public,
  }))

const parseFeaturedCategoriesData = featuredCategories =>
  featuredCategories.map(({ fields }) => ({
    category: parseCategoriesData([fields.category])[0],
    articles: parseArticlesData(fields.articles),
  }))

const parseFeaturedArticlesData = (categories, featuredArticles) => {
  if (!featuredArticles) return []

  return featuredArticles.map(({ fields: { title, article } }) => {
    const parsedArticle = parseArticlesData([article])[0]
    const linkedCategory = categories.find(({ articles }) =>
      articles.some(({ articleId }) => articleId === parsedArticle.articleId),
    )

    return {
      title,
      categoryId: linkedCategory?.categoryId,
      article: parsedArticle,
    }
  })
}

const parseFeaturedQuestionsData = featuredQuestions => {
  if (!featuredQuestions) return []

  return featuredQuestions.map(({ fields: { title, body } }) => ({
    title,
    body,
  }))
}

const parseKnowledgeBaseData = data => {
  const {
    categories,
    featuredCategories,
    featuredArticles,
    featuredQuestions,
  } = data.items[0].fields
  const parsedCategories = parseCategoriesData(categories)

  return {
    categories: parsedCategories,
    featuredCategories: parseFeaturedCategoriesData(featuredCategories),
    featuredArticles: parseFeaturedArticlesData(
      parsedCategories,
      featuredArticles,
    ),
    featuredQuestions: parseFeaturedQuestionsData(featuredQuestions),
  }
}

export const useKnowledgeBaseCategories = () => {
  const { fetchEntries, isLoading, items } = useContentful({
    clientQuery: {
      include: 3,
      content_type: 'knowledgeBase',
      'fields.platform': 'web',
    },
    responseParser: parseKnowledgeBaseData,
  })

  useEffect(() => {
    fetchEntries()
  }, [])

  const {
    categories = [],
    featuredCategories = [],
    featuredArticles = [],
    featuredQuestions = [],
  } = items

  return {
    isLoading,
    categories,
    featuredCategories,
    featuredArticles,
    featuredQuestions,
  }
}

export const getPublicCategories = categories =>
  categories.filter(({ isPublic }) => isPublic)
