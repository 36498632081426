import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { isEmpty } from 'lodash/fp'

import { NoTemplates } from 'components/Icons/Templates'
import { ROUTES } from 'consts'
import FilteringNoResults from 'components/NoResults/FilteringNoResults'

import messages from '../messages'
import { EmptyListContent, EmptyPageHeading, EmptyPageText } from './styles'

const EmptyTemplate = ({
  filteringSettings,
  updateFilteringSettings,
  scrollToTopOfList,
}) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const withFilters =
    !isEmpty(filteringSettings.filters) || filteringSettings.search

  const resetFilters = () => {
    scrollToTopOfList()
    updateFilteringSettings({
      filters: {},
      search: '',
    })
  }

  return (
    <EmptyListContent data-test-id="empty-template" withFilters={withFilters}>
      {withFilters ? (
        <FilteringNoResults onClearFilters={resetFilters} />
      ) : (
        <>
          <NoTemplates />
          <EmptyPageHeading>
            {formatMessage(messages.emptyView)}
          </EmptyPageHeading>
          <EmptyPageText
            onClick={() => navigate(ROUTES.CATEGORIES)}
            data-test-id="empty-template-text"
          >
            {formatMessage(messages.goToCategories)}
          </EmptyPageText>
        </>
      )}
    </EmptyListContent>
  )
}

export default EmptyTemplate
