import React, { useEffect, useMemo, useState } from 'react'

import { useCustomFormikHandlers, useInterval } from 'hooks'
import { formatTime, secondsDiff } from 'utils/datetime'
import TextInput from 'components/Fields/TextInput'
import TwButton from 'components/Button/TwButton'
import { Mail, MailSmall, PhoneSmall } from 'components/Icons'
import ActionWrapper, { ACTION_TYPE } from 'components/ActionWrapper'
import { useRichFormatMessage } from 'utils'

import messages from './messages'
import validationSchema from './validationSchema'

const OTP = ({
  onSubmit,
  otpError,
  description,
  submitText,
  requestOtp,
  otpData,
  setOtpError,
  isOtpLoading,
  submitVariant,
}) => {
  const formatMessage = useRichFormatMessage()

  const [secondsLeft, setSecondsLeft] = useState(0)

  const { otpResettableAt } = otpData

  useEffect(
    () => {
      setSecondsLeft(secondsDiff(otpResettableAt))
    },
    [otpResettableAt],
  )

  const isSendAgainAvailable = secondsLeft < 1

  useInterval(() => {
    const diff = secondsDiff(otpResettableAt)
    if (diff < 1) {
      setSecondsLeft(0)
    } else {
      setSecondsLeft(diff)
    }
  }, !isSendAgainAvailable ? 1000 : null)

  const { minutes, seconds } = useMemo(() => formatTime(secondsLeft), [
    secondsLeft,
  ])

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useCustomFormikHandlers({
    initialValues: {
      otp: '',
    },
    onSubmit,
    validationSchema,
  })

  const handleValueChange = e => {
    const newValue = e.target.value.replace(/[\D\s]/g, '')
    setOtpError(false)
    handleChange(e, newValue.substring(0, 6))
  }

  const formattedValue =
    values.otp.length > 3
      ? `${values.otp.substring(0, 3)} ${values.otp.substring(3, 6)}`
      : values.otp

  return (
    <div className="px-4 py-10 w-full mb-10 md:w-[600px] md:my-20 md:mx-auto md:p-0">
      <div className="flex flex-col gap-8">
        <h1 className="text-h1">{formatMessage(messages.header)}</h1>
        <div className="flex flex-col gap-6">
          <span className="text-sm whitespace-pre-wrap">{description}</span>
          <TextInput
            name="otp"
            inputClassName="text-h1 text-center"
            value={formattedValue}
            touched={touched.otp}
            handleChange={handleValueChange}
            handleBlur={handleBlur}
            placeholder={formatMessage(messages.placeholder)}
            label={formatMessage(messages.label)}
            isError={otpError || (touched.otp && errors.otp)}
            errorText={formatMessage(errors.otp || messages.invalid)}
          />
        </div>
        <TwButton variant={submitVariant} onClick={handleSubmit}>
          <span className="text-label-13md">{submitText}</span>
        </TwButton>
        <div className="flex flex-col gap-[88px]">
          <div className="flex gap-4 items-center">
            <Mail className="stroke-grey-500" />
            {isSendAgainAvailable ? (
              <button
                type="button"
                onClick={requestOtp}
                disabled={isOtpLoading}
                className="text-13 font-medium cursor-pointer hover:text-green-600"
              >
                {formatMessage(messages.sendAgain)}
              </button>
            ) : (
              <span className="text-13 text-grey-500 [&>b]:text-blue-900">
                {formatMessage(messages.sendAgainTimer, {
                  time: `${minutes}:${seconds}`,
                })}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <span className="text-h3">
                {formatMessage(messages.needHelp)}
              </span>
              <span className="text-12">{formatMessage(messages.help)}</span>
            </div>

            <div className="flex flex-col gap-4 lg:flex-row">
              <ActionWrapper
                actionType={ACTION_TYPE.PHONE}
                value={formatMessage(messages.contactsPhone)}
                className="py-2.5 px-4 flex gap-4 bg-grey-150 hover:bg-grey-400 flex-1 text-13 rounded-lg items-center"
              >
                <PhoneSmall className="fill-grey-500" />
                {formatMessage(messages.contactsPhone)}
              </ActionWrapper>
              <ActionWrapper
                actionType={ACTION_TYPE.EMAIL}
                value={formatMessage(messages.contactsMail)}
                className="py-2.5 px-4 flex gap-4 bg-grey-150 hover:bg-grey-400 flex-1 text-13 rounded-lg items-center"
              >
                <MailSmall className="stroke-grey-500" />
                {formatMessage(messages.contactsMail)}
              </ActionWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OTP
