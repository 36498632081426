import React from 'react'

import { cn } from 'utils'

const Item = ({ children, title, icon, onClick, isHighlighted }) => (
  <div
    data-test-id={`kb-list-item#${title}`}
    className={cn(
      'flex items-center rounded-lg',
      isHighlighted && 'bg-green-600',
    )}
  >
    <button
      type="button"
      data-test-id={`kb-list-item-btn#${title}`}
      disabled={isHighlighted}
      className={cn(
        'flex w-full px-4 py-2 gap-4 hover:bg-grey-200',
        children ? 'rounded-s-lg' : 'rounded-lg',
      )}
      onClick={onClick}
    >
      <img
        alt=""
        src={icon}
        style={
          isHighlighted
            ? {
                filter:
                  'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(339deg) brightness(106%) contrast(105%)',
              }
            : {}
        }
      />
      <span
        className={cn(
          'text-blue-900 text-[12px] leading-6 font-medium text-left',
          isHighlighted && 'text-white',
        )}
      >
        {title}
      </span>
    </button>
    {children}
  </div>
)

export default Item
