import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { cn } from 'utils'
import messages from './messages'

const OrderListHeader = ({
  ordersCount,
  searchComponent,
  isSearchActive,
  setIsSearchActive,
  hasSearch,
}) => {
  const { formatMessage } = useIntl()

  // prevent state updates on nested input clicks if already open
  const handleSearchBtnClick = useCallback(
    () => {
      if (isSearchActive) return null

      return setIsSearchActive(true)
    },
    [isSearchActive, setIsSearchActive],
  )

  return (
    <div
      data-test-id="order-list-header"
      className="z-[2] border-y border-solid border-grey-300 bg-white sticky top-[calc(theme(inset.lg-nav-height)-1px)] py-[7px] px-[calc(50vw-50%)] mx-[calc(50%-50vw)]"
    >
      <div className="relative grid grid-cols-[repeat(6,minmax(0,1fr))127px] w-full gap-[17px] px-4">
        <div className="text-left content-center py-2">
          <div className="flex items-center max-h-5 border-r border-solid border-grey-300">
            <span className="text-11">
              {formatMessage(messages.orderNumber)}
            </span>
          </div>
        </div>
        <div className="flex self-center py-2">
          <div className="flex w-full items-center justify-end pr-6 max-h-5 border-r border-solid border-grey-300">
            <span className="text-11">{formatMessage(messages.total)}</span>
          </div>
        </div>
        <div className="pl-4 content-center py-2">
          <div className="flex items-center max-h-5 border-r border-solid border-grey-300">
            <span className="text-11">{formatMessage(messages.status)}</span>
          </div>
        </div>
        <div className="pl-4 content-center py-2">
          <div className="flex items-center max-h-5 border-r border-solid border-grey-300">
            <span className="text-11">
              {formatMessage(messages.orderDatetime)}
            </span>
          </div>
        </div>
        <div className="pl-4 content-center py-2">
          <div className="flex items-center max-h-5 border-r border-solid border-grey-300">
            <span className="text-11">
              {formatMessage(messages.deliveryDate)}
            </span>
          </div>
        </div>
        <div className="text-left pl-4 content-center py-2">
          <div className="flex items-center max-h-5 border-r border-solid border-grey-300">
            <span className="text-11">
              {formatMessage(messages.orderSource)}
            </span>
          </div>
        </div>
        <button
          className={cn(
            'group flex pr-1 pl-2 py-2 ml-[-15px] items-center rounded-lg',
            !!hasSearch && 'hover:bg-grey-100',
          )}
          type="button"
          onClick={handleSearchBtnClick}
          disabled={!hasSearch}
        >
          <div className="text-right">
            <span className="text-11">
              {formatMessage(messages.orders)}
              <span data-test-id="orders-count" className="text-grey-600 ml-2">
                {ordersCount}
              </span>
            </span>
          </div>
          {searchComponent}
        </button>
      </div>
    </div>
  )
}

export default OrderListHeader
