import React, { useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import { cn } from 'utils'
import { ArrowDownNew as ArrowIcon } from 'components/Icons'

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="text-13">{children}</p>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        target="_blank"
        href={node.data.uri}
        className="text-green-600 underline underline-offset-2"
      >
        {children}
      </a>
    ),
  },
}

const FeaturedQuestionAccordion = ({ featuredQuestion: { title, body } }) => {
  const [isExpanded, setExpanded] = useState(false)

  return (
    <div
      data-test-id="kb-featured-question"
      className={cn(
        'flex flex-col gap-4 rounded-lg first:rounded-t-3xl last:rounded-b-3xl hover:bg-grey-400 lg:gap-6',
        isExpanded && 'rounded-3xl bg-grey-400',
      )}
    >
      <button
        type="button"
        className={cn(
          'flex flex-row p-6 justify-between text-left md:p-8',
          isExpanded && 'pb-0 md:pb-0',
        )}
        onClick={() => setExpanded(state => !state)}
      >
        <h3 className="text-h3">{title}</h3>
        <div
          className={cn(
            'transition duration-300 ease-out',
            isExpanded && 'rotate-180',
          )}
        >
          <ArrowIcon />
        </div>
      </button>
      {isExpanded && (
        <div className="px-6 pb-6 md:px-8 md:pb-8">
          {documentToReactComponents(body, renderOptions)}
        </div>
      )}
    </div>
  )
}

export default FeaturedQuestionAccordion
