import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { ROUTES } from 'consts'
import { PROFILE_ROUTES } from 'containers/Profile/consts'
import { ALERT_TYPE } from 'components/Alert'
import { notifyAction } from 'containers/App/actions'
import agreementsActions, {
  toggleNewsletterSubscription,
} from 'containers/Profile/Agreements/actions'
import { agreementsDataSelector } from 'containers/Profile/Agreements/selectors'
import { PROFILE_EVENTS } from 'services/analytics'

import Dropdown from './Dropdown'
import CookieIcon from './Icons/Cookie'
import messages from './messages'
import { CookieSettingsButton, CookieBtnTitle } from './styles'
import { DropdownDescriptionLink } from './Dropdown/styles'

const Consents = ({ formatMessage }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const agreementsData = useSelector(agreementsDataSelector) || []

  useEffect(() => {
    dispatch(agreementsActions.delta())
  }, [])

  const handleSwitch = (id, value) =>
    dispatch(agreementsActions.updateDelta({ id, value }))

  const notify = ({ message, type }) =>
    dispatch(notifyAction({ message, type }))
  const onSuccessNewsletter = value => {
    if (value) {
      notify({
        message: formatMessage(messages.subscribeToast),
        type: ALERT_TYPE.SUCCESS,
      })
    } else {
      notify({
        message: formatMessage(messages.unsubscribeToast),
        type: ALERT_TYPE.SUCCESS,
      })
      navigate(PROFILE_ROUTES.NEWSLETTER_FEEDBACK)
    }
  }
  const onNewsletterSwitch = (id, value) =>
    dispatch(
      toggleNewsletterSubscription({
        id,
        value,
        meta: {
          onSuccess: () => onSuccessNewsletter(value),
        },
      }),
    )

  const config = [
    {
      title: formatMessage(messages.termsTitle),
      description: formatMessage(messages.termsDescription, {
        termsLink: (
          <DropdownDescriptionLink href={ROUTES.TERMS} target="_blank">
            {formatMessage(messages.termsLink)}
          </DropdownDescriptionLink>
        ),
        privacyLink: (
          <DropdownDescriptionLink href={ROUTES.PRIVACY} target="_blank">
            {formatMessage(messages.privacyLink)}
          </DropdownDescriptionLink>
        ),
      }),
      dataId: 1,
      testId: 'terms',
      onSwitch: value => handleSwitch(1, value),
      dropdownEvent: PROFILE_EVENTS.TERMS_CLICKED,
    },
    {
      title: formatMessage(messages.newsletterTitle),
      description: formatMessage(messages.newsletterDescription),
      dataId: 2,
      testId: 'newsletter',
      onSwitch: value => onNewsletterSwitch(2, value),
      dropdownEvent: PROFILE_EVENTS.NEWSLETTER_CLICKED,
    },
    {
      title: formatMessage(messages.marketingTitle),
      description: formatMessage(messages.marketingDescription),
      dataId: 3,
      testId: 'marketing',
      onSwitch: value => handleSwitch(3, value),
      dropdownEvent: PROFILE_EVENTS.MARKETING_CLICKED,
    },
  ]

  return (
    <>
      {config.map(
        ({ title, description, dataId, testId, onSwitch, dropdownEvent }) => (
          <Dropdown
            key={dataId}
            testId={`profile-dropdown-${testId}`}
            title={title}
            description={description}
            data={agreementsData.find(({ id }) => id === dataId)}
            onSwitch={value => onSwitch(value)}
            {...{ dropdownEvent }}
          />
        ),
      )}

      {/* eslint-disable-next-line no-undef */}
      <CookieSettingsButton onClick={() => OneTrust.ToggleInfoDisplay()}>
        <i>
          <CookieIcon />
        </i>
        <CookieBtnTitle>
          {formatMessage(messages.cookieSettingsBtn)}
        </CookieBtnTitle>
      </CookieSettingsButton>
    </>
  )
}

export default Consents
