import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import { PROFILE_ROUTES } from 'containers/Profile/consts'
import { confirmUnsubscribeFromNewsletterActions } from './actions'

const UnsubscribeComponent = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const dispatch = useDispatch()

  useEffect(
    () => {
      dispatch(
        confirmUnsubscribeFromNewsletterActions.delta(
          { token },
          {
            onSuccess: () => {
              navigate(PROFILE_ROUTES.NEWSLETTER_FEEDBACK, {
                state: {
                  suppressBack: true,
                },
              })
            },
          },
        ),
      )
    },
    [dispatch],
  )

  return <div />
}

export default UnsubscribeComponent
