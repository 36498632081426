import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useIntl } from 'react-intl'
import { Flex } from '@rebass/grid'
import { some } from 'lodash/fp'
import { deburr } from 'lodash'

import { ROUTES } from 'consts'
import { useChangeLocationMutation } from 'containers/Locations/rtkApi'
import { useUpdateSettingsMutation } from 'containers/Settings/rtkApi'
import { ICONS_POSITION, ICONS, NormalIcon } from 'components/Icon'
import { BUTTON_SIZE } from 'components/Button'
import Switch from 'components/Input/Switch'

import messages from '../messages'
import {
  BoxWrapper,
  Header,
  SearchContainer,
  SearchInput,
  SearchIconContainer,
} from './styledComponents'
import Item from './Item'

const ChooseLocation = ({
  header,
  locations: fullLocationList = [],
  settings: { skipLocationSelection } = {},
}) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const [searchValue, setSearchValue] = useState('')

  const locations = useMemo(
    () =>
      fullLocationList.filter(
        some(val => {
          const valueString = val ? deburr(val.toLowerCase()) : ''
          const searchString = searchValue
            ? deburr(searchValue.toLowerCase())
            : ''

          return valueString.includes(searchString)
        }),
      ),
    [fullLocationList, searchValue],
  )

  const handleSearchChange = useCallback(({ target: { value } }) => {
    setSearchValue(value)
  }, [])

  const [updateSettings] = useUpdateSettingsMutation()
  const [changeLocation] = useChangeLocationMutation()
  const handleChangeLocation = async customerNo => {
    await changeLocation({ customerNo })

    navigate(ROUTES.DASHBOARD)
  }

  return (
    <BoxWrapper>
      <Header>{header}</Header>

      <Switch
        label={formatMessage(messages.askAtLogin)}
        checked={!skipLocationSelection}
        onChange={checked =>
          updateSettings({ skipLocationSelection: !checked })
        }
        mb="24px"
      />

      <SearchContainer>
        <SearchInput
          placeholder={formatMessage(messages.searchPlaceholder)}
          data-test-id="search_delivery_location"
          onChange={handleSearchChange}
          defaultBg
        />
        <SearchIconContainer>
          <NormalIcon
            data-test-id="search_delivery_location_icon"
            type={ICONS.SEARCH}
            iconPosition={ICONS_POSITION.CENTER}
            size={BUTTON_SIZE.ICON}
          />
        </SearchIconContainer>
      </SearchContainer>

      <Flex mt="8px" flexDirection="column">
        {locations.map(({ customerNo, ...rest }, index) => (
          <Item
            {...rest}
            {...{ customerNo }}
            key={customerNo}
            data-test-id={customerNo}
            withoutBorder={index === locations.length - 1}
            onClick={() => handleChangeLocation(customerNo)}
          />
        ))}
      </Flex>
    </BoxWrapper>
  )
}

export default ChooseLocation
