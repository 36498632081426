import React, {
  useEffect,
  useLayoutEffect,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router'
import { useIntl } from 'react-intl'
import { debounce } from 'lodash'
import { useOfMinWidth } from 'hooks'

import { ROUTES } from 'consts'
import { updateSearchParams } from 'utils'
import Breadcrumbs from 'components/Layout/Breadcrumbs/MainPage'
import { withPageView, SCREENS } from 'services/analytics'
import { useLazyGetOrdersQuery } from 'containers/Orders/List/rtkApi'

import { ORDERS_ROUTES, ORDERS_LIST_DESKTOP_START } from '../consts'
import messages from '../messages'

import NoOrders from './components/NoOrders'
import OrderList from './components/OrderList'
import Skeleton from './components/Skeleton'
import OrderListHeader from './components/OrderListHeader'
import Search from './components/Search'

const SEARCH_MIN_LENGTH = 3

const OrdersList = () => {
  const isDesktop = useOfMinWidth(ORDERS_LIST_DESKTOP_START)
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const [searchParams, setSearchParams] = useSearchParams()
  const current = searchParams.get('current') || 0
  const ordersSearch = searchParams.get('ordersSearch') || ''

  const [searchInputValue, setSearchInputValue] = useState(() => ordersSearch)
  const [isSearchActive, setIsSearchActive] = useState(() => !!ordersSearch)

  const [
    fetchOrders,
    { data: { orders, meta } = {}, isFetching: isOrdersFetching },
  ] = useLazyGetOrdersQuery()

  const ordersPagination = meta?.pagination || {}
  const { currentPage, totalPages, totalCount } = ordersPagination

  useEffect(
    () => {
      window.scrollTo({ top: 0 })
    },
    [currentPage],
  )

  // used to eliminate flickering for cached results on re-renders
  useLayoutEffect(
    () => {
      const isInvalidPage = Number.isNaN(Number(current)) || current < 1
      const isOutOfRange = totalPages < current && totalPages !== 0

      if (isInvalidPage) {
        setSearchParams(prev => updateSearchParams(prev, { current: 1 }), {
          replace: true,
        })
      } else if (isOutOfRange) {
        setSearchParams(
          prev => updateSearchParams(prev, { current: totalPages }),
          { replace: true },
        )
      } else {
        fetchOrders({ page: current, search: ordersSearch }, true)
      }
    },
    [setSearchParams, totalPages, current, ordersSearch],
  )

  const handleShowOrderDetails = orderId =>
    navigate(generatePath(ORDERS_ROUTES.DETAILS, { orderId }))
  const handleGoToCatalog = () => navigate(ROUTES.DASHBOARD)

  const handlePageChange = useCallback(
    page => {
      setSearchParams(prev => updateSearchParams(prev, { current: page }))
    },
    [setSearchParams],
  )

  const validateSearch = useCallback(
    currentValue => {
      if (currentValue === '') {
        searchParams.delete('ordersSearch')
        return setSearchParams(updateSearchParams(searchParams, { current: 1 }))
      }

      if (currentValue.length >= SEARCH_MIN_LENGTH) {
        return setSearchParams(prev =>
          updateSearchParams(prev, { current: 1, ordersSearch: currentValue }),
        )
      }

      return null
    },
    [searchParams, setSearchParams],
  )

  const debouncedSearch = useMemo(() => debounce(validateSearch, 500), [
    validateSearch,
  ])

  const handleSearchClear = useCallback(
    () => {
      setSearchInputValue('')
      debouncedSearch('')
      setIsSearchActive(false)
    },
    [setSearchInputValue, debouncedSearch, setIsSearchActive],
  )

  const hasSearch = !!(totalCount || ordersSearch)

  const listComponent = useMemo(
    () =>
      totalCount ? (
        <OrderList
          onShowOrderDetails={handleShowOrderDetails}
          onPageChange={handlePageChange}
          ordersPagination={ordersPagination}
          isFetching={isOrdersFetching}
          orders={orders}
        />
      ) : (
        <NoOrders
          onClick={ordersSearch ? handleSearchClear : handleGoToCatalog}
          isQuery={!!ordersSearch}
        />
      ),
    [
      totalCount,
      handleShowOrderDetails,
      handlePageChange,
      ordersPagination,
      isOrdersFetching,
      orders,
      ordersSearch,
      handleSearchClear,
      handleGoToCatalog,
    ],
  )

  const searchComponent = useMemo(
    () => (
      <Search
        value={searchInputValue}
        setValue={setSearchInputValue}
        isActive={isSearchActive}
        setIsActive={setIsSearchActive}
        validateSearch={validateSearch}
        debouncedSearch={debouncedSearch}
        onClearBtnClick={handleSearchClear}
        hasSearch={hasSearch}
      />
    ),
    [
      hasSearch,
      searchInputValue,
      setSearchInputValue,
      isSearchActive,
      setIsSearchActive,
      validateSearch,
      debouncedSearch,
      handleSearchClear,
    ],
  )

  return (
    <div className="w-full p-4 pb-6 lg-orders-list:px-8 lg-orders-list:pb-10">
      <div className="flex flex-col gap-8 mb-4 lg-orders-list:gap-12">
        <Breadcrumbs />
        <h2 className="text-m-h2 lg-orders-list:text-h1">
          {formatMessage(messages.ordersTitle)}
        </h2>
      </div>
      {isDesktop ? (
        <OrderListHeader
          ordersCount={totalCount}
          searchComponent={searchComponent}
          isSearchActive={isSearchActive}
          setIsSearchActive={setIsSearchActive}
          hasSearch={hasSearch}
        />
      ) : (
        <div className="flex items-center py-4">
          {searchComponent}
          <span className="text-11">
            {formatMessage(messages.orders)}
            <span data-test-id="orders-count" className="text-grey-600 ml-2">
              {totalCount}
            </span>
          </span>
        </div>
      )}
      <div className="flex min-h-[438px]">
        {isOrdersFetching ? <Skeleton /> : listComponent}
      </div>
    </div>
  )
}

export default withPageView(SCREENS.ORDERS)(OrdersList)
