import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'

import theme, { paymentTheme } from 'theme'

import { HistoryButton } from 'views/Dashboard/Invoices/Button/styles'
import { ROUTES } from 'consts'
import { TEXT_COLOR_BY_VARIANT, VARIANT } from 'views/Dashboard/Invoices/consts'
import { EVENT_ORIGIN, trackClickPaymentsHistory } from 'services/analytics'
import { useSelector } from 'react-redux'
import { userDataSelector } from 'containers/UserInfo/selectors'
import Button, { BUTTON_VARIANT } from '../../Button'
import { FOOTER_OFFSET, FooterContainer, FooterText } from './styles'
import messages from '../../messages'

const CONFIG_BY_VARIANT = {
  [VARIANT.YELLOW]: {
    bgColor: paymentTheme.yellow.bg,
    txtColor: TEXT_COLOR_BY_VARIANT[VARIANT.YELLOW],
    btnVariant: BUTTON_VARIANT.YELLOW,
    shadowColor: paymentTheme.yellow.shadow,
  },
  [VARIANT.RED]: {
    bgColor: theme.colors.redLight3,
    txtColor: TEXT_COLOR_BY_VARIANT[VARIANT.RED],
    btnVariant: BUTTON_VARIANT.LIGHT_RED,
    shadowColor: paymentTheme.yellow.shadow,
  },
  [VARIANT.GREEN]: {
    btnVariant: BUTTON_VARIANT.GREEN,
  },
}

const Footer = ({
  variant,
  message,
  IconComponent,
  btnLabel,
  onBtnClick,
  btnTestID,
}) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { customerNo, storeId } = useSelector(userDataSelector)
  const config = CONFIG_BY_VARIANT[variant] || {}
  const offset =
    variant === VARIANT.RED || variant === VARIANT.YELLOW
      ? FOOTER_OFFSET.INNER
      : FOOTER_OFFSET.MOBILE_SIDE_BOTTOM
  const hasOrderHistoryButton =
    variant === VARIANT.GREY || variant === VARIANT.GREEN

  return (
    <FooterContainer
      data-test-id="footer-overlay"
      $offset={offset}
      $shadowColor={config.shadowColor}
      style={{ backgroundColor: config.bgColor }}
    >
      {message && (
        <FooterText style={{ color: config.txtColor }}>{message}</FooterText>
      )}

      {onBtnClick && (
        <Button
          variant={config.btnVariant}
          IconComponent={IconComponent}
          onClick={onBtnClick}
          data-test-id={btnTestID}
        >
          {btnLabel}
        </Button>
      )}

      {hasOrderHistoryButton && (
        <HistoryButton
          data-test-id="show-payment-history-btn"
          onClick={() => {
            navigate(`${ROUTES.INVOICES.ROOT}?type=all`)
            trackClickPaymentsHistory({
              customerNo,
              storeId,
              origin: EVENT_ORIGIN.DASHBOARD,
            })
          }}
        >
          {formatMessage(messages.paymentHistoryBtn)}
        </HistoryButton>
      )}
    </FooterContainer>
  )
}

export default Footer
