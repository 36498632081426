import React from 'react'
import { generatePath } from 'react-router'
import { useSelector } from 'react-redux'
import { Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { formatPrice } from 'utils'

import { allDeliveryDatesSelector } from 'containers/Delivery/selectors'

import { Link } from 'components/Text'
import { Box as BoxIcon, Comment, Truck, TruckOk } from 'components/Icons'

import { ORDERS_ROUTES } from 'views/Orders/consts'
import { Section } from '../styles'
import DeliveryInfo from '../DeliveryInfo'
import messages from '../messages'

import InfoRow from './InfoRow'
import Products from './Products'
import {
  CommentWrapper,
  Header,
  InfoWrapper,
  DeliveryWrapper,
  MobileSeparator,
  OrderInfoWrapper,
  OrderInfoInnerWrapper,
  ProductsWrapper,
  SmallText,
} from './styles'
import { getLimit } from './utils'

const { colors, spacing } = theme

const ProductsSection = ({ orders, isMobile }) => {
  const deliveryDates = useSelector(allDeliveryDatesSelector)
  const { formatMessage } = useIntl()
  const Wrapper = isMobile
    ? props => <Flex flexDirection="column" {...props} />
    : Section

  return (
    <Wrapper>
      <Header>{formatMessage(messages.ordersHeader)}</Header>

      <Flex flexDirection="column">
        {orders.map((order, index) => {
          const {
            deliveryDate,
            routeId,
            routeName,
            orderNumber,
            totalGross,
            orderComment,
            orderItems,
            orderId,
          } = order
          const orderPath = generatePath(ORDERS_ROUTES.DETAILS, { orderId })
          const limitLack = getLimit({ deliveryDates, deliveryDate, routeId })
          const deliveryValue = limitLack
            ? formatMessage(messages.lacksToFreeDelivery, {
                amount: formatPrice(limitLack),
              })
            : formatMessage(messages.free)
          const commentRendered = orderComment && (
            <CommentWrapper data-test-id="order-comment">
              <SmallText>{orderComment}</SmallText>

              <Comment />
            </CommentWrapper>
          )

          return (
            <DeliveryWrapper key={`${deliveryDate}-${routeId}`}>
              <InfoWrapper>
                <DeliveryInfo
                  withoutLines
                  ordersLength={orders.length}
                  {...{ deliveryDate, routeName, index }}
                  ml={isMobile ? '-8px' : 0}
                />

                {isMobile && <MobileSeparator />}

                <Flex flexDirection="column" mr={theme.spacing.sm}>
                  <OrderInfoWrapper>
                    <OrderInfoInnerWrapper>
                      <InfoRow
                        label="orderNumber"
                        value={orderNumber}
                        rightSideColor={colors.primary}
                        mb={spacing.xxs}
                        ValueWrapper={props => (
                          <Link to={orderPath} target="_blank" {...props} />
                        )}
                      />

                      <InfoRow
                        label="grossSum"
                        value={formatPrice(totalGross)}
                      />
                    </OrderInfoInnerWrapper>

                    <BoxIcon />
                  </OrderInfoWrapper>

                  <OrderInfoWrapper alignItems="center" mt={spacing.sm}>
                    <InfoRow
                      label="delivery"
                      rightSideColor={
                        limitLack ? colors.redDarkText : colors.secondary
                      }
                      value={deliveryValue}
                    />

                    {limitLack ? <Truck /> : <TruckOk />}
                  </OrderInfoWrapper>
                </Flex>

                {/* prettier-ignore */ isMobile && commentRendered && (
                  <>
                    <MobileSeparator />
                    {commentRendered}
                  </>
                )}
              </InfoWrapper>

              {!isMobile && commentRendered}

              <ProductsWrapper>
                <Products {...{ orderItems }} />
              </ProductsWrapper>
            </DeliveryWrapper>
          )
        })}
      </Flex>
    </Wrapper>
  )
}

export default ProductsSection
