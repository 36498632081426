import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'
import { allCountries } from 'country-telephone-data'
import { Portal } from 'react-portal'
import { omit } from 'lodash/fp'
import { useModal } from '@ebay/nice-modal-react'

import { cn } from 'utils'
import { RECAPTCHA_KEY, ROUTES } from 'consts'
import { trimValues } from 'utils/redux-form-utils'
import { useCustomFormikHandlers } from 'hooks'
import TextInput from 'components/Fields/TextInput'
import Switch from 'components/Switch'
import TwButton from 'components/Button/TwButton'
import { ArrowDownV2Big, ArrowFullRight, CircleAlert } from 'components/Icons'
import LoaderFullHeight from 'components/LoaderFullHeight'
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from 'components/Popup'
import {
  useCheckLoginMutation,
  useSignUpMutation,
  useLazyGetCompanyQuery,
} from 'containers/Auth/rtkApi'
import { ACCOUNT_ERRORS } from 'containers/Auth/consts'
import { notifyFailure } from 'components/Product/utils'
import InlineLink from 'components/Navigation/InlineLink'
import { PROFILES } from './consts'
import ProfileMultiselect from './ProfileMultiselect'

import SignUpErrorModal from './ErrorModal'
import { scrollToFirstError, useSignUpValidation } from './utils'
import messages from './messages'
import Succeed from './Succeed'

const countriesList = allCountries.map(({ iso2, dialCode, name }) => ({
  value: iso2.toUpperCase(),
  label: `+${dialCode} ${name}`,
  iso2,
  dialCode,
  name,
}))

const SignUp = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const signUpErrorModal = useModal(SignUpErrorModal)

  const [dropdownWidth, setDropdownWidth] = useState(0)
  const [confirmedProfiles, setConfirmedProfiles] = useState([])
  const phoneNoRef = useRef(null)
  const updateDropdownWidth = () => {
    setDropdownWidth(phoneNoRef.current?.scrollWidth)
  }

  const [
    signUpUser,
    {
      isLoading: isSignUpLoading,
      isSuccess: isSignUpSuccess,
      isError: isSignUpError,
      error: signUpError,
    },
  ] = useSignUpMutation()

  const [
    checkLogin,
    {
      isLoading: isCheckLoading,
      isError: isCheckFailed,
      reset: resetLoginCheckState,
    },
  ] = useCheckLoginMutation()

  const [getCompany] = useLazyGetCompanyQuery()

  useEffect(
    () => {
      if (isSignUpError) {
        const accountExistsError = signUpError.data.errors.find(error =>
          Object.values(ACCOUNT_ERRORS).includes(error.code),
        )

        if (accountExistsError) {
          signUpErrorModal.show({ errorCode: accountExistsError.code })
          checkLogin()
        } else {
          dispatch(notifyFailure(messages.tryAgain))
        }
      }
    },
    [isSignUpError, signUpError],
  )

  const isLoading = isSignUpLoading || isCheckLoading
  const validate = useSignUpValidation()

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    setErrors,
    isSubmitting,
  } = useCustomFormikHandlers({
    initialValues: {
      login: '',
      customerVatId: '',
      customerName: '',
      customerNo: '',
      customerFirstName: '',
      customerLastName: '',
      emailAddress: '',
      deliveryCountryCode: countriesList.find(({ value }) => value === 'PL'),
      phoneNo: '',
      deliveryAddress: '',
      deliveryPostCode: '',
      deliveryCity: '',
      profiles: [],
      otherProfile: '',
      agreement_1: false,
      agreement_2: false,
      agreement_3: false,
      agreement_4: false,
      captcha: false,
    },
    onSubmit: fieldValues => {
      const { otherProfile } = fieldValues
      const allProfiles = confirmedProfiles.reduce(
        (acc, profile) => [
          ...acc,
          profile === PROFILES.OTHER && otherProfile
            ? `${PROFILES.OTHER}: ${otherProfile}`
            : profile,
        ],
        [],
      )

      return signUpUser(
        omit(
          ['captcha', 'otherProfile'],
          trimValues({ ...fieldValues, profiles: allProfiles }),
        ),
      )
    },
    validate,
  })

  useEffect(
    () => {
      if (!isSubmitting) {
        scrollToFirstError(isCheckFailed ? { ...errors, login: true } : errors)
      }
    },
    [isSubmitting],
  )

  const hasOtherProfileField = confirmedProfiles.includes(PROFILES.OTHER)
  useEffect(
    () => {
      // reset on showing/hiding other profile field
      setFieldValue('otherProfile', '')
      setFieldError('otherProfile', '')
      setFieldTouched('otherProfile', false)
    },
    [hasOtherProfileField],
  )

  const isAllAccepted =
    values.agreement_1 && values.agreement_2 && values.agreement_3

  const toggleAllAgreements = () => {
    if (isAllAccepted) {
      setValues(prevValues => ({
        ...prevValues,
        agreement_1: false,
        agreement_2: false,
        agreement_3: false,
      }))
    } else {
      setValues(prevValues => ({
        ...prevValues,
        agreement_1: true,
        agreement_2: true,
        agreement_3: true,
      }))
    }
  }

  const handleLoginChange = e => {
    handleChange(e)

    if (isCheckFailed) {
      resetLoginCheckState()
    }
  }

  const handleLoginBlur = async e => {
    const validationError = await handleBlur(e)
    if (!validationError) {
      checkLogin({ login: e.target.value })
    }
  }

  const handleCountryCodeChange = newValue => {
    setValues(prevValues => ({
      ...prevValues,
      deliveryCountryCode: newValue,
    }))
  }

  const handleCaptchaChange = value => {
    setValues(prevValues => ({
      ...prevValues,
      captcha: value,
    }))
    setErrors({
      ...errors,
      captcha: null,
    })
  }

  if (isSignUpSuccess) {
    return <Succeed email={values.emailAddress} />
  }

  return (
    <>
      {isLoading && (
        <Portal key="portal">
          <LoaderFullHeight />
        </Portal>
      )}

      <div className="flex flex-col overflow-x-hidden gap-8 px-4 md:grid md:grid-cols-4 md:gap-y-12 md:gap-x-8 md:px-20 pb-20">
        <div className="pb-4 pt-12 px-2 md:col-start-2 md:md:col-span-2 md:pt-[88px] md:pb-10 flex flex-col gap-12">
          <h3
            className="text-2xl md:text-4xl md:leading-[48px] font-semibold"
            data-test-id="reg_title"
          >
            {/* span tag needed to prevent break word at hyphen in the title */}
            {formatMessage(messages.header, {
              title: text => <span className="text-nowrap">{text}</span>,
            })}
          </h3>
          <span className="text-base md:text-lg leading-7">
            {formatMessage(messages.description)}
          </span>
        </div>

        <div className="md:col-span-4 bg-grey-300 h-px" />

        <h4 className="mx-2 md:mx-0 text-h2">Login</h4>
        <div className="md:col-span-2 flex flex-col gap-4">
          <TextInput
            name="login"
            value={values.login}
            testId="reg_login"
            touched={touched.login}
            placeholder={formatMessage(messages.loginPlaceholder)}
            handleChange={handleLoginChange}
            handleBlur={handleLoginBlur}
            errorText={
              isCheckFailed && formatMessage(messages.loginAlreadyTaken)
            }
            isError={isCheckFailed || (touched.login && errors.login)}
          />
          <div className="flex gap-4">
            <span
              className={cn(
                'mx-2 text-grey-600 text-13',
                !isCheckFailed &&
                  (touched.login && errors.login) &&
                  'text-red-700',
              )}
            >
              {formatMessage(messages.loginInvalid)}
            </span>
          </div>
        </div>

        <div className="md:col-span-4 bg-grey-300 h-px" />

        <h4 className="mx-2 md:mx-0 text-h2">
          {formatMessage(messages.businessDetails)}
        </h4>
        <div className="md:col-span-2 flex flex-col gap-6">
          <TextInput
            name="customerVatId"
            labelClassName="ml-2"
            label={formatMessage(messages.customerVatId)}
            value={values.customerVatId}
            touched={touched.customerVatId}
            placeholder={formatMessage(messages.customerVatIdPlaceholder)}
            handleChange={e =>
              handleChange(e, e.target.value.replace(/\D/g, ''))
            }
            handleBlur={async e => {
              handleBlur(e)

              if (values.customerVatId.length !== 10) return

              const { data, error } = await getCompany(values.customerVatId)

              if (error) {
                console.log('Error getting company by NIP', error)
                return
              }

              if (data.name) {
                setFieldValue('customerName', decodeURIComponent(data.name))
                setFieldTouched('customerName', true)
                setFieldError('customerName', '')
              }
            }}
            errorText={errors.customerVatId}
            isError={touched.customerVatId && errors.customerVatId}
            hint={
              touched.customerVatId && formatMessage(messages.customerVatIdHint)
            }
          />
          <TextInput
            name="customerName"
            labelClassName="ml-2"
            label={formatMessage(messages.customerName)}
            value={values.customerName}
            touched={touched.customerName}
            placeholder={formatMessage(messages.customerNamePlaceholder)}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errorText={errors.customerName}
            isError={touched.customerName && errors.customerName}
          />
          <TextInput
            name="customerNo"
            testId="reg_customerNo"
            labelClassName="ml-2"
            label={formatMessage(messages.customerNo)}
            value={values.customerNo}
            touched={touched.customerNo}
            placeholder={formatMessage(messages.customerNoPlaceholder)}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errorText={errors.customerNo}
            isError={touched.customerNo && errors.customerNo}
          />
          <ProfileMultiselect
            confirmedProfiles={confirmedProfiles}
            setConfirmedProfiles={setConfirmedProfiles}
          />
          {hasOtherProfileField && (
            <TextInput
              name="otherProfile"
              labelClassName="ml-2"
              label={formatMessage(messages.otherProfile)}
              value={values.otherProfile}
              touched={touched.otherProfile}
              placeholder={formatMessage(messages.otherProfilePlaceholder)}
              handleChange={handleChange}
              handleBlur={handleBlur}
              hint={formatMessage(messages.otherProfileHint)}
              errorText={errors.otherProfile}
              isError={touched.otherProfile && errors.otherProfile}
              hasHintIcon
            />
          )}
        </div>

        <div className="md:col-span-4 bg-grey-300 h-px" />

        <h4 className="mx-2 md:mx-0 text-h2">
          {formatMessage(messages.contactData)}
        </h4>
        <div className="md:col-span-2 flex flex-col gap-6">
          <div className="flex flex-col gap-6 md:flex-row md:gap-4">
            <TextInput
              name="customerFirstName"
              labelClassName="ml-2"
              label={formatMessage(messages.customerFirstName)}
              value={values.customerFirstName}
              touched={touched.customerFirstName}
              placeholder={formatMessage(messages.customerFirstNamePlaceholder)}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errorText={errors.customerFirstName}
              isError={touched.customerFirstName && errors.customerFirstName}
            />
            <TextInput
              name="customerLastName"
              labelClassName="ml-2"
              label={formatMessage(messages.customerLastName)}
              value={values.customerLastName}
              touched={touched.customerLastName}
              placeholder={formatMessage(messages.customerLastNamePlaceholder)}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errorText={errors.customerLastName}
              isError={touched.customerLastName && errors.customerLastName}
            />
          </div>
          <TextInput
            name="emailAddress"
            labelClassName="ml-2"
            label={formatMessage(messages.emailAddress)}
            value={values.emailAddress}
            touched={touched.emailAddress}
            placeholder={formatMessage(messages.emailAddressPlaceholder)}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errorText={errors.emailAddress}
            isError={touched.emailAddress && errors.emailAddress}
          />
          <div data-test-id="phoneNo" className="flex flex-col gap-2">
            <label htmlFor="phone_no" className="mx-2 text-12">
              {formatMessage(messages.phoneNo)}
            </label>
            <Popover placement="bottom-start" disableArrow>
              <div className="flex flex-col gap-2">
                <div className="flex gap-4" ref={phoneNoRef}>
                  <PopoverTrigger asChild>
                    <button
                      type="button"
                      onClick={updateDropdownWidth}
                      className="flex justify-between bg-grey-150 py-3 px-4 rounded-lg outline-none hover:bg-grey-400 focus:bg-grey-400 duration-300"
                    >
                      <span className="text-base">{`+${
                        values.deliveryCountryCode.dialCode
                      }`}</span>
                      <ArrowDownV2Big className="stroke-blue-900" />
                    </button>
                  </PopoverTrigger>
                  <TextInput
                    id="phone_no"
                    name="phoneNo"
                    value={values.phoneNo}
                    touched={touched.phoneNo}
                    placeholder={formatMessage(messages.phoneNoPlaceholder)}
                    handleChange={e =>
                      handleChange(e, e.target.value.replace(/\D/g, ''))
                    }
                    handleBlur={handleBlur}
                    isError={touched.phoneNo && errors.phoneNo}
                  />
                </div>
                {touched.phoneNo &&
                  errors.phoneNo && (
                    <div className="flex gap-2">
                      <CircleAlert />
                      <span className="text-red-700 text-label-11">
                        {errors.phoneNo}
                      </span>
                    </div>
                  )}
              </div>
              <PopoverContent style={{ width: dropdownWidth }}>
                <div className="bg-white shadow-modal rounded-2xl flex flex-col p-4 max-h-80 overflow-auto scroll-p-4">
                  {countriesList.map(item => (
                    <PopoverClose
                      key={item.value}
                      onClick={() => handleCountryCodeChange(item)}
                      className="text-label-13 cursor-pointer py-2 px-4 rounded-lg hover:bg-grey-200"
                    >
                      {item.label}
                    </PopoverClose>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>

        <div className="md:col-span-4 bg-grey-300 h-px" />

        <h4 className="mx-2 md:mx-0 text-h2">
          {formatMessage(messages.deliveryAddress)}
        </h4>
        <div className="md:col-span-2 flex flex-col gap-6">
          <TextInput
            name="deliveryAddress"
            labelClassName="ml-2"
            label={formatMessage(messages.streetAndNumber)}
            value={values.streetAddress}
            touched={touched.deliveryAdress}
            placeholder={formatMessage(messages.streetAndNumberPlaceholder)}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errorText={errors.deliveryAddress}
            isError={touched.deliveryAddress && errors.deliveryAddress}
          />

          <div className="flex flex-col gap-6 md:flex-row md:gap-4">
            <TextInput
              name="deliveryPostCode"
              labelClassName="ml-2"
              label={formatMessage(messages.deliveryPostCode)}
              value={values.deliveryPostCode}
              touched={touched.deliveryPostCode}
              placeholder={formatMessage(messages.deliveryPostCodePlaceholder)}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errorText={errors.deliveryPostCode}
              isError={touched.deliveryPostCode && errors.deliveryPostCode}
            />
            <TextInput
              name="deliveryCity"
              labelClassName="ml-2"
              label={formatMessage(messages.deliveryCity)}
              value={values.deliveryCity}
              touched={touched.deliveryCity}
              placeholder={formatMessage(messages.deliveryCityPlaceholder)}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errorText={errors.deliveryCity}
              isError={touched.deliveryCity && errors.deliveryCity}
            />
          </div>
        </div>

        <div className="md:col-span-4 bg-grey-300 h-px" />

        <h4 className="mx-2 md:mx-0 text-h2">
          {formatMessage(messages.consentsTitle)}
        </h4>
        <div className="px-4 flex flex-col gap-4 md:col-span-2 md:px-2">
          <Switch
            value={isAllAccepted}
            handleChange={toggleAllAgreements}
            label={formatMessage(messages.markAllAgreements)}
            testId="accept-all"
          />

          <Switch
            name="agreement_1"
            value={values.agreement_1}
            handleChange={handleChange}
            errorText={errors.agreement_1}
            isError={errors.agreement_1}
            label={
              <>
                {formatMessage(messages.terms)}
                <span className="text-xs font-normal">
                  {formatMessage(messages.termsRequired)}
                </span>
              </>
            }
          />
          <span className="pb-2 text-grey-800 text-12">
            {formatMessage(messages.termsContent, {
              terms: text => (
                <InlineLink href={ROUTES.TERMS}>{text}</InlineLink>
              ),
              privacy: text => (
                <InlineLink href={ROUTES.PRIVACY}>{text}</InlineLink>
              ),
            })}
          </span>

          <div className="w-full bg-grey-300 h-px" />

          <Switch
            name="agreement_2"
            value={values.agreement_2}
            touched={touched.agreement_2}
            handleChange={handleChange}
            label={formatMessage(messages.newsletter)}
          />
          <span className="pb-2 text-grey-800 text-12">
            {formatMessage(messages.newsletterContent)}
          </span>

          <div className="w-full bg-grey-300 h-px" />

          <Switch
            name="agreement_3"
            value={values.agreement_3}
            touched={touched.agreement_3}
            handleChange={handleChange}
            label={formatMessage(messages.marketing)}
          />
          <span className="text-grey-800 text-12">
            {formatMessage(messages.marketingContent)}
          </span>
        </div>

        <div className="md:col-span-4 bg-grey-300 h-px" />

        <div className="flex flex-col gap-2 md:col-start-2 md:col-span-2">
          <ReCAPTCHA
            sitekey={RECAPTCHA_KEY}
            onChange={handleCaptchaChange}
            onExpired={() => handleCaptchaChange('')}
            hl="pl"
          />
          {errors.captcha && (
            <div className="flex gap-2">
              <CircleAlert />
              <span className="text-red-700 text-label-11">
                {formatMessage(errors.captcha)}
              </span>
            </div>
          )}
        </div>

        <TwButton
          data-test-id="register_submit"
          className="md:col-start-2 md:col-span-2"
          onClick={handleSubmit}
        >
          <span className="flex justify-center gap-4 text-label-13md">
            {formatMessage(messages.submit)}
            <ArrowFullRight className="stroke-white" />
          </span>
        </TwButton>
      </div>
    </>
  )
}

export default SignUp
