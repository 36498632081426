import React from 'react'
import { useNavigate, generatePath } from 'react-router'
import { useIntl } from 'react-intl'

import { ROUTES } from 'consts'
import { ArrowDownV2 as ArrowIcon } from 'components/Icons'

import messages from '../../../messages'

const FeaturedCategoryTile = ({ category, articles }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const { title, theme, img, categoryId } = category

  const bgColor = theme.backgroundColor.value
  const txtColor = theme.textColor.value

  const handleArticleClick = articleId =>
    navigate(
      generatePath(ROUTES.KNOWLEDGE_BASE, {
        category: categoryId,
        article: articleId,
      }),
    )
  const handleMoreClick = () =>
    navigate(generatePath(ROUTES.KNOWLEDGE_BASE, { category: categoryId }))

  return (
    <div
      data-test-id="kb-featured-category"
      className="flex flex-col px-4 pt-8 rounded-3xl w-full h-[640px] md:h-[368px] md:!bg-right-bottom md:!bg-[length:50%] lg:h-[640px] lg:!bg-bottom lg:!bg-contain"
      style={{
        background: `${bgColor} url(${img}) no-repeat bottom/contain`,
        color: txtColor,
      }}
    >
      <div className="flex flex-col">
        <div className="flex flex-col gap-[14px] px-4">
          <div className="border border-solid rounded-md px-1 w-fit">
            <span className="text-label-11md leading-5">
              {formatMessage(messages.topicsCount, {
                count: category.articles.length,
              })}
            </span>
          </div>
          <h1 className="text-h1">{title}</h1>
        </div>
        <div className="flex flex-col gap-[2px] mt-4 mb-2">
          {articles.map(art => (
            <button
              key={art.articleId}
              type="button"
              className="px-4 py-2 rounded-lg text-left hover:bg-grey-200"
              onClick={() => handleArticleClick(art.articleId)}
            >
              <span className="text-13md">{art.title}</span>
            </button>
          ))}
        </div>
        <button
          type="button"
          className="flex items-center gap-2 px-4 py-2 rounded-lg hover:bg-grey-200"
          onClick={handleMoreClick}
        >
          <span className="text-13md">{formatMessage(messages.more)}</span>
          <div className="-rotate-90">
            <ArrowIcon color={txtColor} />
          </div>
        </button>
      </div>
    </div>
  )
}

export default FeaturedCategoryTile
