import * as yup from 'yup'

import validationMessages from 'utils/validators/messages'

export default yup.object().shape({
  email: yup
    .string()
    .matches(
      /^([-.+\w]+)@((?:[-\w]+\.)+[a-zA-Z]{2,})$/,
      validationMessages.emailFormat,
    )
    .required(validationMessages.required),
})
